import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import {createPinia} from 'pinia'
import i18n from './locales/i18n'
import PrimeVue from 'primevue/config';
import ReadMore from 'vue-read-more';
import {ResizeObserver} from 'vue3-resize'


// import "primevue/resources/themes/bootstrap4-light-blue/theme.css";
import "primeflex/primeflex.css"
import 'primeicons/primeicons.css';
import "./assets/styles/style.scss"
import 'vue3-resize/dist/vue3-resize.css'


const pinia = createPinia()

import Button from "primevue/button"
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import Dialog from 'primevue/dialog';
import ProgressBar from 'primevue/progressbar';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Row from 'primevue/row';
import OverlayPanel from 'primevue/overlaypanel';
import Calendar from 'primevue/calendar';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Checkbox from 'primevue/checkbox';
import InputSwitch from 'primevue/inputswitch';
import Sidebar from 'primevue/sidebar';
import TabMenu from 'primevue/tabmenu';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import VirtualScroller from 'primevue/virtualscroller';
import DeferredContent from 'primevue/deferredcontent';
import RadioButton from 'primevue/radiobutton';
import Image from 'primevue/image';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import Password from 'primevue/password';

import Paginate from "vuejs-paginate-next";

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';



createApp(App)
    .use(router)
    .use(pinia)
    .use(i18n)
    .use(ReadMore)
    .use(PrimeVue)
    .use(ToastService)
    .use(Paginate)
    .use(VueTelInput)
    .component('Toast', Toast)
    .component('resize-observer', ResizeObserver)
    .component('Button', Button)
    .component('InputText', InputText)
    .component('Dropdown', Dropdown)
    .component('MultiSelect', MultiSelect)
    .component('Dialog', Dialog)
    .component('ProgressBar', ProgressBar)
    .component('DataTable', DataTable)
    .component('Column', Column)
    .component('ColumnGroup', ColumnGroup)
    .component('Row', Row)
    .component('OverlayPanel', OverlayPanel)
    .component('Calendar', Calendar)
    .component('Accordion', Accordion)
    .component('AccordionTab', AccordionTab)
    .component('Checkbox', Checkbox)
    .component('InputSwitch', InputSwitch)
    .component('Sidebar', Sidebar)
    .component('TabMenu', TabMenu)
    .component('VirtualScroller', VirtualScroller)
    .component('DeferredContent', DeferredContent)
    .component('RadioButton', RadioButton)
    .component('Image', Image)
    .component('InputMask', InputMask)
    .component('InputNumber', InputNumber)
    .component('Password', Password)
    .mount('#app')

