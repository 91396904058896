<template>
  <div class="header">
    <div class="logo">
      <img src="images/logo.svg" alt="logo" @click="$router.push({name: 'dashboard'})">
    </div>
    <i class="pi pi-bars burger-icon" @click="menuMob = true"></i>
    <Sidebar v-model:visible="menuMob" :showCloseIcon="false">
      <img src="images/logo.svg" alt="logo" class="logo-mob">
      <menu-mob version="mobile"/>
    </Sidebar>
    <div class="wrapper">
      <div class="balance-block">
        <span class="balance-text">{{$t('t.due_payout')}}: </span>
        <span class="balance">${{ balance }}</span>
      </div>
      <Locales/>
    </div>
  </div>
</template>

<script>
import MenuMob from "@/components/menu";
import {getBalanceApi} from "@/api/profile";
import Locales from "@/components/locales.vue";

export default {
  name: "header",
  components: {MenuMob, Locales},
  data() {
    return {
      menuMob: false,
      balance: ''
    }
  },
  mounted() {
    this.getBalance()
  },
  methods: {
    getBalance() {
      getBalanceApi().then((resp) => {
        this.balance = Number(resp.data.result.toFixed(2))
      }, (error) => {
        console.log(error.message)
      })
    },
  }
}
</script>

<style scoped lang="scss">
.header {
  position: relative;
  z-index: 99;
  background-color: #ffffff;
  height: 80px;
  display: flex;
  align-items: center;
}

.wrapper {
  width: calc(100% - 220px);
  display: flex;
  justify-content: end;
  align-items: center;
  height: 100%;
  box-shadow: 10px 4px 11px 1px #88A2FF1F;
}

.logo {
  width: 220px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
}

.balance-block {
  background-color: #F0F3F9;
  border-radius: 8px;
  height: 40px;
  width: 201px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 25px;
}

.balance-text {
  color: #03192F;
  font-size: 14px;
  font-weight: 500;
  margin-right: 8px;
}

.balance {
  color: var(--primary-color);
  font-weight: 600;
}

.burger-icon {
  display: none;
}

@media screen and (max-width: 767px) {
  .header {
    width: 100%;
    position: fixed;
    padding: 0 20px;
    box-shadow: 10px 4px 11px 1px #88A2FF1F;
    height: 64px;
  }
  .logo {
    display: none;
  }
  .wrapper {
    width: 100%;
    box-shadow: none;
  }
  .burger-icon {
    font-size: 28px;
    display: block;
  }
  .balance-block {
    margin-right: 15px;
  }
  .logo-mob {
    margin: 15px 0 15px 1rem;
  }
}
</style>
