<template>
  <div class="text-small">{{$t('t.text.modal_invoice')}}</div>
  <MultiSelect v-model="orders" display="chip" :options="listOrders" optionLabel="date" :loading="loadingOrders"
               :maxSelectedLabels="10" class="mb-4 w-full" placeholder="Select" panelClass="multi-select-custom"
               :autoOptionFocus="false">
    <template #chip="slotProps">
      {{ slotProps.value.periodFrom }} - {{ slotProps.value.periodFrom }} ({{ slotProps.value.amount }}
      {{ slotProps.value.currency }})
    </template>
    <template #option="slotProps">
      <div>{{ slotProps.option.periodFrom }} - {{ slotProps.option.periodFrom }} ({{ slotProps.option.amount }}
        {{ slotProps.option.currency }})
      </div>
    </template>
  </MultiSelect>
  <label class="space-upload" for="file-input" v-if="!filePreview">
    <div class="text-upload">{{$t('t.text.drag_drop')}}</div>
    <div class="btn-upload">{{$t('t.text.select_file')}}</div>
    <input id="file-input" type="file" ref="file" accept=".pdf" style="opacity: 0; height: 0" @change="uploadFile">
  </label>
  <template v-if="filePreview">
    <div class="upload-block" :class="{'upload-done': doneUpload}">
      <div class="flex">

        <div class="avatar-preview"><img alt src="images/file_png.png"></div>
        <div class="w-full flex flex-column justify-content-around">
          <div class="flex justify-content-between align-items-center">
            <span class="name-img">{{ fileInfo.name }}</span>
            <img v-if="!doneUpload" @click="filePreview = null" class="close" src="images/icons/close.svg" alt/>
            <img v-if="doneUpload" class="close" src="images/icons/trash-solid.svg" alt/>
          </div>
          <div class="flex justify-content-between">
            <span class="size">{{ fileInfo.size }}</span>
            <div>
              <div v-if="doneUpload" class="completed"><img src="images/check-green.png" alt/><span>{{$t('t.completed')}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProgressBar v-if="visible" mode="indeterminate" class="mt-2" style="height: 4px" color="#007bff"></ProgressBar>
    </div>
    <div class="text-center pt-2">
      <Button :label="$t('t.save')" style="width: 100px" :disabled="!orders.length" @click="createInvoice"/>
    </div>
  </template>

</template>

<script>
import {createInvoiceApi} from "@/api/wallet";

export default {
  name: "modalInvoice",
  props: ["type", "listOrders", "loadingOrders"],
  data() {
    return {
      filePreview: null,
      visible: false,
      fileInfo: {},
      doneUpload: false,
      orders: []
    }
  },
  mounted() {
    if (!this.loadingOrders && !this.listOrders.length) this.$emit('initOrders')
  },
  methods: {
    createInvoice() {
      const data = {invoiceType: "payment"}
      data.orders = this.orders.map(o => {
        return o.id
      })
      data.invoice = this.fileInfo.file
      let formData = new FormData();
      for (let key in data) {
        formData.append(key, data[key]);
      }
      createInvoiceApi(formData, this.type).then(() => {
        this.$toast.add({severity: 'success', detail: this.$t("t.message.add_invoice"), life: 3000});
        this.$emit('close')
      }, (error) => {
        for(let item of error.response.data.errors) {
          this.$toast.add({severity: 'error', detail: item, life: 3000});
        }
      })
    },
    uploadFile() {
      let file = this.$refs.file.files[0]
      const types = ["application/pdf"]
      if (types.includes(file.type)) {
        let reader = new FileReader();
        reader.addEventListener("load", function () {
          this.filePreview = reader.result;
        }.bind(this), false);
        reader.readAsDataURL(file)
        let i = 0
        const type = ['B', 'KB', 'MB'];
        let size = file.size
        while ((size / 1000 | 0) && i < type.length - 1) {
          size = size / 1024;
          i++;
        }
        this.fileInfo = {
          name: file.name,
          size: size.toFixed(0) + type[i],
          file: file
        }
      } else console.log('Error upload file')
    },
  }
}
</script>

<style scoped lang="scss">
.text-small {
  color: #61697B;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 8px;
}

::v-deep(.p-multiselect-token) {
  border-radius: 5px !important;
  font-size: 12px;
}

::v-deep(.p-multiselect-token-icon) {
  color: #8E9BBB;
}

@media screen and (max-width: 767px) {
  ::v-deep(.p-multiselect-token) {
    font-size: 10px !important;
  }
}

</style>
