<template>
  <div class="wrapper" ref="wrapper" @scroll="onScroll">
    <template v-for="_new in listData">
    <div class="new" v-if="_new.translations[local]">
      <div class="status hidden md:block" v-if="checkNew(_new.created_at)">{{$t('t.new')}}</div>
      <div class="image"><img alt :src="_new.translations[local].image? _new.translations[local].image : _new.image"></div>
      <div class="flex flex-column">
        <span class="date">{{ formatDate(_new.created_at) }}</span>
        <span class="title">{{  _new.translations[local].title  }}</span>
        <div class="text" v-html="_new.translations[local].text"></div>
      </div>
    </div>
      <div class="new" v-else>
        <div class="status hidden md:block" v-if="checkNew(_new.created_at)">{{$t('t.new')}}</div>
        <div class="image"><img alt :src="_new.image"></div>
        <div class="flex flex-column">
          <span class="date">{{ formatDate(_new.created_at) }}</span>
          <span class="title">{{  _new.title  }}</span>
        </div>
      </div>
    </template>
  </div>

</template>

<script>
import moment from "moment";
import {getArticlesApi} from "@/api/article";

export default {
  name: "index",
  data() {
    return {
      listData: [],
      maxPage: 1,
      currentPage: 1,
      lazy: false
    }
  },
  mounted() {
    this.getNews()
  },
  computed: {
    local() {
      return this.$i18n.locale
    }
  },
  methods: {
    checkNew(date){
      const diff = moment().diff(moment(date), 'days')
      return diff <= 7? true : false
    },
    onScroll(e) {
      if (!this.lazy && this.currentPage < this.maxPage) {
        if (e.target.offsetHeight + e.target.scrollTop > e.target.scrollHeight - 350) {
          ++this.currentPage
          this.getNews()
        }
      }
    },
    formatDate(date) {
      if (date) {
        return moment(date).format("DD.MM.YYYY")
      } else return ''
    },
    getNews() {
      this.lazy = true
      getArticlesApi('news', {page: this.currentPage, newest:1}).then((resp) => {
        this.listData.push(...resp.data.items)
        // this.maxPage = resp.data.pagination.numPages
        this.lazy = false
      }, (error) => {
        console.log(error.message)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  overflow-y: auto;
  height: calc(100% + 24px);
  margin: 0 -20px -24px 0;
  padding-right: 20px;
  padding-bottom: 24px;
}

.status {
  position: absolute;
  right: 0;
  top: 0;
  width: 59px;
  height: 26px;
  padding: 8px 16px 8px 16px;
  border-radius: 0 10px 0 10px;
  background-color: #2970C9;
  color: #FFFFFF;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
}

.new {
  background-color: #FFFFFF;
  border-radius: 12px;
  padding: 20px;
  height: 300px;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 24px;

  .image {
    width: 260px;
    margin-right: 20px;
    img{
      aspect-ratio: 1 / 1;
      border-radius: 8px;
      object-fit: cover;
      width: inherit;
    }
  }

  .date {
    font-weight: 400;
    font-size: 12px;
    color: #212529;
    margin-bottom: 12px;
  }

  .title {
    font-weight: 600;
    font-size: 11px;
    text-transform: uppercase;
    color: #212529;
    margin-bottom: 12px;
  }

  .text {
    font-weight: 400;
    font-size: 12px;
    color: #000000;
    line-height: 16px;
    display: block;
    overflow: auto;
    max-height: 100%;
  }
}

@media screen and (max-width: 767px) {
  .wrapper {
    overflow-y: hidden;
    height: 100%;
    margin: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
  .new {
    flex-direction: column;
    height: auto;

    .image {
      border-radius: 0;
      width: 100%;
      margin-bottom: 10px;
    }
  }
}
</style>
