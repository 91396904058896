<template>
  <div class="block md:flex h-full">
    <TabMenu v-model:activeIndex="currentBlock" :model="blocks" class="blocks-mob">
      <template #item="{ item}">
        <div class="block-mob" :class="currentBlock == item.index? 'active': ''">{{ item.name }}
        </div>
      </template>
    </TabMenu>
    <div class="grid w-full">
      <div class="md:col-9 ms:col-12 history">
        <TabsMenu v-model="typeActive"/>
        <template v-for="type in activeTypes">
          <payment-history v-if="mainStore().typeProfile" :currentBlock="currentBlock" :versionMobile="versionMobile"
                           :type="type" v-show="typeActive == type"/>
          <payment-history v-else :currentBlock="currentBlock" :versionMobile="versionMobile" :type="type"/>
        </template>
      </div>
      <div class="md:col-3 ms:col-12 methods">
        <methods :currentBlock="currentBlock" :width="versionMobile"/>
      </div>
    </div>
  </div>
</template>

<script>
import PaymentHistory from "@/pages/Finance/components/paymentHistory";
import Methods from "@/pages/Finance/components/methods";
import {mainStore} from "@/store/main";
import TabsMenu from "@/components/tabsMenu.vue";

export default {
  name: "index",
  methods: {mainStore},
  components: {TabsMenu, Methods, PaymentHistory},
  data() {
    return {
      currentBlock: 0,
      activeTypes: [],
      typeActive: null

    }
  },
  mounted() {
    if (mainStore().typeProfile) {
      this.activeTypes.push(mainStore().typeProfile[0].id)
    } else this.activeTypes = ['']
  },
  watch: {
    typeActive() {
      if (!this.activeTypes.includes(this.typeActive)) {
        this.activeTypes.push(this.typeActive)
      }
    }
  },
  computed: {
    blocks() {
      return [
        {index: 0, name: this.$t('t.payment_history')},
        {index: 1, name: this.$t('t.payment_methods')},
        {index: 2, name: this.$t('t.add_method')},
      ]
    },
    versionMobile() {
      return mainStore().versionDisplay
    }
  },
}
</script>

<style scoped lang="scss">
.history {
  padding: 0 !important;
}

.methods {
  padding: 0 !important;
}

.blocks-mob {
  display: none;
}

.grid {
  margin: 0;
}

.methods {
  height: -webkit-fill-available;
}

@media screen and (max-width: 767px) {
  ::v-deep(.p-tabmenu-nav) {
    padding-left: 10px;
  }
  .blocks-mob {
    margin: 0 -20px 20px -20px;
    display: block;

    .block-mob {
      padding: 8px 16px;
      border-radius: 8px;
      border: 1px solid var(--primary-color);
      margin-right: 10px;
      color: var(--primary-color);
      font-size: 12px;
      font-weight: 600;
      width: max-content;
    }
  }
  .history {
    width: 100%;
  }
  .methods {
    width: 100%;
  }
}

.active {
  background-color: var(--primary-color);
  color: #ffffff !important;
}

::v-deep(.p-tabmenu-nav) {
  border: none !important;
}

</style>
