import axios from "axios";



const apiAuth = axios.create({
    withCredentials: true,
    baseURL: process.env.VUE_APP_API_URL,
});

export function loginApi(data) {
    return apiAuth.post('/auth/login', data)
}

export function resetPasswordApi(data) {
    return apiAuth.post('/auth/reset', data)
}

export function getFormSignUpApi() {
    return apiAuth.get(`/auth/sign-up`)
}

export function signUpApi(data) {
    return apiAuth.post('/auth/sign-up', data)
}

export function getNewPasswordApi(token) {
    return apiAuth.get(`/auth/reset/${token}`)
}

export function newPasswordApi(token, data) {
    return apiAuth.post(`/auth/reset/${token}`, data)
}
