<template>
  <Dropdown v-if="!multi" v-model="value" :options="options" :optionValue="optionValue" :optionLabel="optionLabel"
            :autoOptionFocus="false"
            class="solo w-full" :placeholder="name" ref="dropdown"
            panelClass="custom-drop-panel" @show="drop = true" @hide="drop=false" @change="$emit('change')">
    <template #value v-if="!viewValue">
      <span class="p-placeholder">{{ name }}</span>
    </template>
    <template #dropdownicon>
      <img v-if="!drop" src="images/icons/arrow-down-solo.svg"/>
      <img v-if="drop" src="images/icons/arrow-up-solo.svg"/>
    </template>
    <template #option="slotProps">
      <slot :props="slotProps"></slot>
    </template>
  </Dropdown>
  <MultiSelect v-if="multi" v-model="value" :options="options" :optionLabel="optionLabel" :optionValue="optionValue"
               :autoOptionFocus="false"
               class="solo w-full" :placeholder="name" :showToggleAll="false"
               panelClass="custom-drop-panel" @show="drop = true" @hide="drop=false" @change="$emit('change')">
    <template #value v-if="!viewValue">
      <span class="p-placeholder">{{ name }}</span>
    </template>
    <template #dropdownicon>
      <img v-if="!drop" src="images/icons/arrow-down-solo.svg"/>
      <img v-if="drop" src="images/icons/arrow-up-solo.svg"/>
    </template>
  </MultiSelect>
</template>

<script>
export default {
  name: "customDropdown",
  props: {
    multi: {
      type: Boolean,
      default: false
    },
    modelValue: {},
    options: {
      default: []
    },
    name: {
      default: 'Select'
    },
    optionLabel: {
      default: 'name'
    },
    optionValue: {
      default: 'id'
    },
    viewValue: {
      default: false
    },
  },
  data() {
    return {
      drop: false,
      value: this.modelValue,
    }
  },
  watch: {
    modelValue() {
      this.value = this.modelValue
    },
    value() {
      this.$emit('update:modelValue', this.value)
    }
  },
   methods:{
     hide(){
      this.$refs.dropdown.hide()
    }
   }
}
</script>

<style scoped lang="scss">

.solo {
  border-radius: 8px !important;
  border: none;
  background: #EEF2FC;

  ::v-deep(.p-placeholder) {
    color: #03192F !important;
    font-weight: 600;
  }

  ::v-deep(.p-inputtext) {
    padding: 0.55rem !important;
  }
}

::v-deep(.p-multiselect-label) {
  font-size: 0.8rem;
  font-weight: 600;
  color: var(--primary-color);
}
::v-deep(.p-dropdown-label) {
  font-size: 0.8rem;
  font-weight: 600;
  color: var(--primary-color);
}

.p-overlay-open {
  border-radius: 8px 8px 0 0 !important;
  background: var(--primary-color) !important;

  ::v-deep(.p-placeholder) {
    color: #ffffff !important;
  }
  ::v-deep(.p-multiselect-label) {
    color: #ffffff !important;
  }
  ::v-deep(.p-dropdown-label) {
    color: #ffffff !important;
  }
}

.custom-drop-panel {
  box-shadow: 0 4px 16px 1px #4F75FF29;
  border-radius: 0 0 8px 8px !important;

  ::v-deep(.p-inputtext) {
    padding: 0.5rem !important;
  }
}

@media screen and (max-width: 767px) {
  .solo {
    ::v-deep(.p-inputtext) {
      font-size: 12px;
      padding: 0.5rem 0 0.5rem 0.8rem !important;
    }
  }
}
</style>
