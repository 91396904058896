import {api} from "@/api/axios";

export function getCampaignsApi(page, type) {
    const params = {page}
    return api.get(`/campaigns/${type}`, {params})
}

export function getFormCampApi() {
    return api.get(`/campaigns/form/`)
}
export function getLandingsCampApi(id, params = {}) {
    return api.get(`/campaigns/landings/${id}/brand`, {params})
}
export function getLinkCampApi(id) {
    return api.get(`/campaigns/${id}/code`)
}
export function createCampaignApi(data, type) {
    return api.post(`/campaigns/${type}`, data)
}
