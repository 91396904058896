<template>
  <div class="local-btn" @click="$refs.panelLocales.toggle($event)">
    {{ currentLocal }}
  </div>
  <OverlayPanel ref="panelLocales">
    <div v-for="local in locales" @click="changeLocal(local)" class="local-item">{{ local }}</div>
  </OverlayPanel>
</template>

<script>
import {getLocaleApi} from "@/api/cabinet";
import {loadTranslations} from "@/locales/i18n";

export default {
  name: "locales",
  data() {
    return {
      locales: [],
      currentLocal: ''
    }
  },
  created() {
    this.getLocale()
    let locale = localStorage.getItem('locale')
    if (locale) {
      loadTranslations(locale)
    }
  },
  methods: {
    changeLocal(code) {
      loadTranslations(code)
      localStorage.setItem('locale', code)
      this.currentLocal= code
      this.$refs.panelLocales.hide()
    },
    getLocale() {
      getLocaleApi().then((resp) => {
        this.locales = resp.data.items
        let locale = localStorage.getItem('locale')
        if (locale && this.locales.includes(locale)) {
          // loadTranslations(locale)
        } else {
          locale = this.locales[0]
          localStorage.setItem('locale', locale)
          loadTranslations(locale)
        }
        this.currentLocal = locale
      }, (error) => {
        loadTranslations('en')
        console.log(error.message)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.local-btn {
  margin-right: 20px;
  width: 40px;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
}

.local-item {
  cursor: pointer;
  font-weight: 500;
  text-transform: uppercase;

  &:hover {
    color: var(--primary-color);
  }
}

.local-item:not(:last-child) {
  margin-bottom: 10px
}

@media screen and (max-width: 767px) {
  .local-btn {
    margin-right: 0;
    width: auto;
  }
}
</style>
