<template>
  <div class="payment-methods">
    <div class="card-space block-methods" v-show="!width  || currentBlock == 1">
      <div class="header-card flex-column">
        <div class="title-card">{{ $t('t.payment_methods') }}</div>
        <span class="info">{{$t('t.text.methods_block')}}</span>
      </div>

      <div class="wrapper-methods">
        <div class="list-methods">
          <div class="method-card" v-for="(method, key) in listData" :key="method.id"
               :class="{'method-default': method.default, 'method-status': method.status}">
            <Checkbox v-model="method.default" :binary="true" class="mr-3"
                      @change="changeDefaultMethod(method, key)"/>
            <div class="body">
              <div class="flex justify-content-between align-items-center">
                <span class="code">{{ method.name }}</span>
                <div>
                  <InputSwitch v-model="method.status" :trueValue="true" :falseValue="false"
                               @change.stop="dialogActive(method)"/>
                </div>
              </div>
              <span class="id">{{ method.account }}</span>
              <div class="flex justify-content-between">
                <span class="params">{{$t('t.currency')}}: {{ method.currency }}</span>
                <span class="params">{{$t('t.created')}}: {{ formatDate(method.createdAt) }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="blur"></div>
      </div>

    </div>
    <div class="card-space add-methods" v-if="!width  || currentBlock == 2">
      <div class="header-card flex-column ">
        <div class="title-card">{{$t('t.add_method')}}</div>
        <span class="info">{{$t('t.text.new_method_block')}}</span>
      </div>
      <Button :label="$t('t.create_new_method')" style="width: 100%" @click="dialogMethod=true"/>
    </div>
  </div>
  <Dialog v-model:visible="dialogMethod" modal :header="$t('t.add_method')" :style="{ width: '50rem' }"
          :breakpoints="{ '1199px': '75vw', '575px': '90vw' }" class="hide-footer-dialog">
    <ModalAddMethod @newMethod="addMethod($event)"/>
    <template #footer></template>
  </Dialog>
  <Dialog v-model:visible="dialogMethActive" modal :header="$t('t.payment_method_activation')" :style="{ width: '20rem' }"
          :breakpoints="{'575px': '90vw' }" class="hide-footer-dialog">
    <template #header>
      <div class="p-dialog-title w-full active-header"><span v-if="activeMethod.status">{{$t('t.payment_method')}} <br/>{{$t('t.deactivation')}}</span><span
          v-else>{{$t('t.payment_method')}} <br/>{{$t('t.activation')}} </span></div>
    </template>
    <div class="flex flex-column align-items-center">
      <img :src="activeMethod.status? 'images/red_wallet.png': 'images/wallet.png'" width="60px"/>
      <span class="info-text" v-if="activeMethod.status">{{$t('t.text.deactivation_method')}}</span>
      <span class="info-text" v-else>{{$t('t.text.activation_method')}}</span>
      <div class="flex w-full">
        <Button v-if="activeMethod.status" label="Deactivate" class="btn-red" style="width: 50%"
                @click="changeStatusMethod()"/>
        <Button v-else :label="$t('t.confirm')" style="width: 50%" @click="changeStatusMethod()"/>
        <Button :label="$t('t.cancel')" style="width: 50%" class="btn-blue ml-2" @click="dialogMethActive = false"/>
      </div>
    </div>
    <template #footer></template>
  </Dialog>
  <Dialog v-model:visible="dialogDefault" modal :header="$t('t.text.preferred_method_selected')" :style="{ width: '35rem' }"
          :breakpoints="{ '1199px': '40vw', '575px': '90vw' }" class="hide-footer-dialog">
    <div class="text-modal-info">{{$t('t.text.method_default1')}}</div>
    <div class="text-modal-info">{{$t('t.text.method_default2')}}</div>
    <div class="text-modal-info">{{$t('t.text.method_default3')}}</div>
    <div class="text-modal-info">{{$t('t.text.method_default4')}}</div>
    <div class="text-center pt-2">
      <Button :label="$t('t.clear')" style="width: 100px"  @click="dialogDefault = false"/>
    </div>
    <template #footer></template>
  </Dialog>

  <Dialog v-model:visible="dialogDeactivation" modal :header="$t('t.active_method_selected')" :style="{ width: '35rem' }"
          :breakpoints="{ '1199px': '40vw', '575px': '90vw' }" class="hide-footer-dialog">
    <div class="text-modal-info">{{$t('t.text.method_deactivation1')}}</div>
    <div class="text-modal-info">{{$t('t.text.method_deactivation2')}}</div>
    <div class="text-modal-info">{{$t('t.text.method_deactivation3')}}</div>
      <div class="text-modal-info">{{$t('t.text.method_deactivation4')}}</div>
        <div class="text-modal-info">{{$t('t.text.method_deactivation5')}}</div>
    <div class="text-center pt-2">
      <Button :label="$t('t.clear')" style="width: 100px"  @click="dialogDeactivation = false"/>
    </div>
    <template #footer></template>
  </Dialog>

</template>

<script>

import {getUserPaymentMethodsApi, putDefaultMethodApi, putStatusMethodApi} from "@/api/wallet";
import ModalAddMethod from "@/pages/Finance/components/modalAddMethod.vue";
import moment from "moment";

export default {
  name: "methods",
  components: {ModalAddMethod},
  props: ["currentBlock", "width"],
  data() {
    return {
      listData: [],
      status: false,
      dialogMethod: false,
      dialogMethActive: false,
      activeMethod: null,
      defaultMethod: '',
      dialogDefault: false,
      dialogDeactivation: false
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    formatDate(date) {
      if (date) {
        return moment(date).format("DD.MM.YYYY")
      } else return ''
    },
    changeStatusMethod() {
      const data = {
        id: this.activeMethod.id,
        status: !this.activeMethod.status
      }
      const index = this.listData.findIndex(v => v.id == data.id)
      if(this.activeMethod.default){
        putDefaultMethodApi({id: data.id, default: false }).then(() => {
          this.listData[index].default = false
          this.defaultMethod = ''
        }, (error) => {
          console.log(error)
        })
      }
      putStatusMethodApi(data).then(() => {
        this.listData[index].status = data.status
        this.dialogMethActive = false
        this.activeMethod = null
      }, (error) => {
        console.log(error)
      })
    },
    dialogActive(method) {
      const index = this.listData.findIndex(v => v.id == method.id)
      this.listData[index].status = !method.status
      this.dialogMethActive = true
      this.activeMethod = method
    },
    changeDefaultMethod(method, index) {
      const data = {
        id: method.id,
        default: method.default
      }
      if (data.default) {
        this.dialogDefault = true
      } else this.dialogDeactivation = true

      putDefaultMethodApi(data).then((resp) => {
        if (this.defaultMethod !== index) {
          if (resp.data.default) {
            if (typeof this.defaultMethod === 'number' && this.defaultMethod >= 0) {
              this.listData[this.defaultMethod].default = false
            }
            this.defaultMethod = index
          } else this.defaultMethod = ''
        } else this.defaultMethod = ''
      }, (error) => {
        console.log(error)
      })
      delete data.default
      if (!method.default && method.status) {
        data.status = false
        putStatusMethodApi(data).then(() => {
          const index = this.listData.findIndex(v => v.id == data.id)
          this.listData[index].status = data.status
        }, (error) => {
          console.log(error)
        })
      }

      if (method.default && !method.status) {
        data.status = true
        putStatusMethodApi(data).then(() => {
          const index = this.listData.findIndex(v => v.id == data.id)
          this.listData[index].status = data.status
        }, (error) => {
          console.log(error)
        })
      }
    },
    addMethod(data) {
      if (data.default) {
        if (typeof this.defaultMethod === 'number' && this.defaultMethod >= 0) {
          this.listData[this.defaultMethod].default = false
        }
        this.defaultMethod = 0
      }
      this.listData.unshift(data)
      this.defaultMethod = this.listData.findIndex(v => v.default == true)
      this.dialogMethod = false
    },
    getData() {
      getUserPaymentMethodsApi().then((resp) => {
        this.listData = resp.data.items
        this.defaultMethod = this.listData.findIndex(v => v.default == true)
      }, (error) => {
        console.log(error)
      })
    },
  }
}
</script>

<style scoped lang="scss">
.text-modal-info{
  color: #61697B;
  font-size: 14px;
  margin-bottom: 20px;
  line-height: 14px;
}
.active-header {
  text-align: center;
  margin-right: -30px;
  line-height: 20px
}

.info-text {
  margin-top: 20px;
  margin-bottom: 16px;
  text-align: center;
  font-size: 10px
}

.payment-methods {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.block-methods {
  height: 80%;
  position: relative;
  padding-bottom: 0;
  overflow-y: hidden !important;
}

.add-methods {
  height: 17%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.info {
  margin-top: 10px;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
  color: #61697B;
}

.wrapper-methods {
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 25px;
  height: 85%;
  overflow-x: auto;
  overflow-y: scroll;
}

.list-methods {

}

.blur {
  position: absolute;
  width: 100%;
  height: 60px;
  bottom: 0;
  left: 0;
  z-index: 3;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 75.66%, #FFFFFF 100%);
  border-radius: 0 0 12px 12px;
}

.method-card {
  margin-bottom: 8px;
  width: 100%;
  height: 100px;
  padding: 12px;
  border-radius: 5px;
  border: 1px solid transparent;
  background-color: #F0F3F9;
  display: flex;
  align-items: center;

  .body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 86%;

    .code {
      font-size: 12px;
      font-weight: 600;
      line-height: 14px;
      color: #61697B;
    }

    .id {
      font-size: 10px;
      font-weight: 400;
      line-height: 12px;
      color: #03192F;
      word-wrap: break-word;
    }

    .params {
      font-size: 10px;
      font-weight: 400;
      line-height: 12px;
      color: #61697B;
    }
  }
}

.method-status {
  background-color: #DDE5FB;
}

.method-default {
  background-color: rgba(0, 202, 93, 0.1);
  border: 1px solid #00CA5D;
}

@media screen and (max-width: 767px) {
  .payment-methods {
    width: 100%;

  }
  .block-methods {
    height: 76vh;
  }
  .wrapper-methods {
    height: 85%;
  }
  .add-methods {
    //height: 21%;
  }
}
</style>
