<template>
  <div class="tabs-menu">
    <div v-for="tab in tabs" class="tab" :class="activeTab === tab? 'active': ''"
         @click="activeTab = tab">{{ tab }}
    </div>
  </div>
  <Campaigns v-show="activeTab === 'campaigns'"/>
  <template v-if="tabsActive.length === 2">
    <Postback v-show="activeTab === 'postback'"/>
  </template>
</template>

<script>
import Campaigns from "@/pages/Campaigns/Campaigns.vue";
import Postback from "@/pages/Campaigns/Postback.vue";

export default {
  name: "index",
  components: {Campaigns, Postback},
  data() {
    return {
      tabs: ['campaigns', 'postback'],
      activeTab: 'campaigns',
      tabsActive: ['campaigns']
    }
  },
  mounted() {

  },
  watch: {
    activeTab() {
      if (!this.tabsActive.includes(this.activeTab)) {
        this.tabsActive.push(this.activeTab)
      }
    }
  },
  computed: {},
  methods: {},
}
</script>

<style scoped lang="scss">
.tabs-menu {
  display: flex;

  .tab {
    text-transform: capitalize;
    transition: .3s;
    margin-left: -5px;
    cursor: pointer;
    z-index: 1;
    border-radius: 8px 8px 0 0;
    background: #EEF2FC;
    color: #212529;
    font-size: 14px;
    font-weight: 400;
    height: 40px;
    width: 140px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:first-child {
      margin-left: 0;
    }
  }

  .active {
    z-index: 2;
    background: #FFFFFF;
    font-weight: 600;
  }
}
</style>
