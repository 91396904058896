<template>
  <div>
    <template v-for="type in activeTypes">
      <CampaignBlock v-if="mainStore().typeProfile" :type="type" v-show="typeActive == type"><TabsMenu v-model="typeActive" :small="true"/></CampaignBlock>
      <CampaignBlock v-else :type="type" ><TabsMenu v-model="typeActive" :small="true"/></CampaignBlock>
    </template>
  </div>
</template>

<script>
import CampaignBlock from "@/pages/Campaigns/components/campaignBlock.vue";
import TabsMenu from "@/components/tabsMenu.vue";
import {mainStore} from "@/store/main";

export default {
  name: "Campaigns",
  components: {CampaignBlock, TabsMenu},
  data() {
    return {
      activeTypes: [],
      typeActive: null
    }
  },
  mounted() {
    if(mainStore().typeProfile){
      this.activeTypes.push(mainStore().typeProfile[0].id)
    } else this.activeTypes = ['']
  },
  watch: {
    typeActive() {
      if (!this.activeTypes.includes(this.typeActive)) {
        this.activeTypes.push(this.typeActive)
      }
    }
  },
  computed: {},
  methods: {mainStore},
}
</script>

<style scoped lang="scss">

</style>
