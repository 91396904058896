import {api} from "./axios"
export function getOrdersApi(page, type) {
    const params = {page}
    return api.get(`/orders/${type}`, {params})
}
export function getStatusOrderApi() {
    return api.get(`/order/status-list`)
}
export function getFormOrderApi(type) {
    return api.get(`/orders/form/${type}`)
}

