<template>
  <div class="card-space">
    <div class="header-card">
      <div class="title-card">{{$t('t.latest_news')}}</div>
      <router-link tag="a" class="link" :to="{name: 'news'}">{{$t('t.all_news')}}</router-link>
    </div>
    <div class="divider hidden md:block">
      <div style="width: 95%"></div>
    </div>
    <div class="news-grid mt-3">
      <template v-for="_new in listData">
        <div class="card-new" v-if="_new.translations[local]">
          <div class="image"><img alt :src="_new.translations[local].image? _new.translations[local].image : _new.image"></div>
          <span class="date">{{ formatDate(_new.created_at) }}</span>
          <span class="title">{{ _new.translations[local].title }}</span>
          <!--          <div class="text" v-html="_new.text"></div>-->
          <!--          <router-link :to="{name: 'news'}"><Button class="btn-blue w-50" label="Read more" /></router-link>-->
          <read-more v-if="_new.translations[local].text" :more-str="$t('t.read_more')" :text="_new.translations[local].text" link="#" :less-str="$t('t.read_less')"
                     :max-chars="150"
                     class="more"></read-more>
        </div>
        <div class="card-new" v-else>
          <div class="image"><img alt :src="_new.image"></div>
          <span class="date">{{ formatDate(_new.created_at) }}</span>
          <span class="title">{{ _new.title }}</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>


import {getArticlesApi} from "@/api/article";
import moment from "moment/moment";

export default {
  name: "news",
  data() {
    return {
      listData: []
    }
  },
  mounted() {
    this.getNews()
  },
  computed: {
    local() {
      return this.$i18n.locale
    }
  },
  methods: {
    formatDate(date) {
      if (date) {
        return moment(date).format("DD.MM.YYYY")
      } else return ''
    },
    getNews() {
      getArticlesApi('news', {limit: 4, newest:1}).then((resp) => {
        this.listData = resp.data.items
      }, (error) => {
        console.log(error.message)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.card-space {
  height: 77%;
}

.news-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
}

.card-new {
  display: flex;
  flex-direction: column;
  padding: 12px;
  background-color: #F9FBFF;
  border-radius: 10px;

  .image {
    width: 100%;
    margin-bottom: 12px;
    img{
      aspect-ratio: 1 / 1;
      border-radius: 8px;
      object-fit: cover;
      width: inherit;
    }
  }

  .date {
    font-weight: 400;
    font-size: 12px;
    color: #212529;
    margin-bottom: 6px;
  }

  .title {
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    color: #212529;
    margin-bottom: 12px;
  }

  .more {
    font-weight: 400;
    font-size: 10px;
    color: #000000;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    //line-height: 14px;
    //-webkit-box-orient: vertical;
    //display: block;
    //display: -webkit-box;
    //overflow: hidden;
    //text-overflow: ellipsis;
    //-webkit-line-clamp: 8;
    //margin-bottom: 10px;
  }

  ::v-deep(.more) {
    p:first-child {
      overflow: auto;
      height: 5vh;
    }
  }

  ::v-deep(#readmore) {
    color: var(--primary-color) !important;
    margin-top: 5px;
    display: flex;
    background-color: #EEF2FC;
    height: 32px;
    width: 50%;
    text-decoration: none;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
  }

}

@media screen and (max-width: 767px) {
  .card-space {
    height: auto;
  }

  .news-grid {
    grid-template-columns: 1fr;
  }
  .card-new {
    ::v-deep(.more) {
      p:first-child {
        overflow: hidden;
        height: auto;
      }
    }
  }
}
</style>
