<template>
  <Toast/>
  <component :is="layout" v-if="layout == 'login' || mainStore().globalView">
    <router-view/>
  </component>
</template>


<script>
import main from "@/layout/layoutMain";
import login from "@/layout/layoutLogin";
import {mainStore} from "@/store/main";
import {getStatusOrderApi} from "@/api/order";
import {getConditionsApi} from "@/api/profile";
import {usePrimeVue} from "primevue/config";
import {en} from '@/assets/localPrimeVue/en.json';
import {ru} from '@/assets/localPrimeVue/ru.json';

const messages = {
  en,
  ru
}

export default {
  components: {main, login},
  data() {
    return {
      primevue: null
    }
  },
  created() {
    this.versionDisplay()
    window.addEventListener("resize", this.versionDisplay);
    this.primevue = usePrimeVue();
    mainStore().setGlobalLoading(true)
  },
  computed: {
    layout() {
      return this.$route.meta.layout || 'login'
    },
  },
  watch: {
    '$i18n.locale'() {
      this.primevue.config.locale = messages[this.$i18n.locale] || messages.en
    },
    layout() {
      if (this.layout === 'main') {
        this.getProfile()
        this.getStatus()
      }
    }
  },
  methods: {
    mainStore,
    getProfile() {
      getConditionsApi().then((resp) => {
        mainStore().setType(resp.data.items)
        mainStore().setGlobalView(true)
      }, (error) => {
        console.log(error.message)
      })
    },
    versionDisplay() {
      mainStore().setVersionDisplay(window.innerWidth)
    },
    getStatus() {
      getStatusOrderApi().then((resp) => {
        mainStore().setStatus(resp.data.items)
      }, (error) => {
        console.log(error)
      })
    },
  }
}

</script>

<style lang="scss">
#app {
  height: 100vh;
}
</style>
