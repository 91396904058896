import {createRouter, createWebHistory} from 'vue-router'
import Dashboard from "@/pages/Dashboard"
import News from "@/pages/News"
import Faq from "@/pages/Faq"
import Campaigns from "@/pages/Campaigns"
import Finance from "@/pages/Finance"
import Referrals from "@/pages/Referrals"
import Auth from "@/pages/Auth/index.vue"



import {clearLocalStorage, helperToken, helperTokenManager} from "@/helpers";
import {refresh} from "@/api/axios";

const routes = [
    {
        path: '/auth',
        name: 'auth',
        component: Auth,
        meta: {layout: 'login'}
    },
    {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
        meta: {layout: 'main'}
    },
    {
        path: '/statistics',
        name: 'statistics',
        component: () => import('../pages/Statistics'),
        meta: {layout: 'main'}
    },
    {
        path: '/campaigns',
        name: 'campaigns',
        component: Campaigns,
        meta: {layout: 'main'}
    },
    {
        path: '/finance',
        name: 'finance',
        component: Finance,
        meta: {layout: 'main'}
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import('../pages/Profile'),
        meta: {layout: 'main'}
    },
    {
        path: '/referrals',
        name: 'referrals',
        component: Referrals,
        meta: {layout: 'main'}
    },
    {
        path: '/news',
        name: 'news',
        component: News,
        meta: {layout: 'main'}
    },
    {
        path: '/faq',
        name: 'faq',
        component: Faq,
        meta: {layout: 'main'}
    },

]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
            }
        } else return { top: 0 }
    }
})

async function checkAccess() {
    let token = localStorage.getItem('crm_token');
    let expiring = localStorage.getItem('crm_expire');
    if (expiring && token && token.length) {
        if ((new Date).getTime() < expiring) {
            return true
        } else {
            const response = await refresh()
            helperToken(response.data)
            return true
        }
    } else {
        return false
    }
}

router.beforeEach((to, from, next) => {
    if(to.query.jwt){
        helperTokenManager(to.query.jwt)
        next({ name: 'dashboard', query: {} });
    }
    checkAccess().then((access) => {
        if (to.name === 'auth' && access) {
            next({name: 'dashboard'})
        } else if (to.name !== 'auth' && !access) {
            next({name: 'auth'})
        } else {
            next()
        }
    }, () => {
        clearLocalStorage()
        window.history.back();
        next({name: 'auth'})
    })
});
export default router
