<template>
  <div class="flex flex-column align-items-center px-2">
    <Dropdown v-model="newMethod.payMethodId" :options="methods" optionLabel="name" optionValue="id"
              :placeholder="$t('t.pay_method_id')" class="mb-3 w-full"/>
    <InputText v-model.trim="newMethod.account" :placeholder="$t('t.account')" class="mb-3  w-full"
               :disabled="!newMethod.payMethodId"
               :class="{ 'p-invalid': validAccount }"/>
    <Dropdown v-model="newMethod.currency" :options="currencies" optionLabel="currency" optionValue="currency"
              :placeholder="$t('t.currency')"
              class="mb-4  w-full" :disabled="!newMethod.payMethodId"/>
    <div class="flex align-items-center mb-3">
      <Checkbox v-model="newMethod.default" :binary="true" inputId="checkbox"/>
      <label for="checkbox" class="ml-2 label-checkbox">{{$t('t.set_default_method')}}</label></div>
    <Button :label="$t('t.create_new_method')" @click="validate"/>
  </div>
</template>
<script>
import {createMethodApi, getCurrencyApi, getPaymentMethodsApi} from "@/api/wallet";

export default {
  data() {
    return {
      newMethod: {
        payMethodId: 0,
        account: "",
        currency: "usd",
        default: false
      },
      methods: [],
      validAccount: null,
      currencies: []
    }
  },
  mounted() {
    this.getMethods()
  },
  watch: {
    'newMethod.payMethodId'() {
      this.getCurrencies(this.newMethod.payMethodId)
    }
  },
  methods: {
    getCurrencies(id) {
      getCurrencyApi(id).then((resp) => {
        this.currencies = resp.data.items
      }, (error) => {
        console.log(error)
      })
    },
    validate() {
      if (this.newMethod.account.trim().length) {
        let pattern = this.methods.find(v => v.id == this.newMethod.payMethodId).validationPattern
        pattern = new RegExp(pattern);
        if (pattern.test(this.newMethod.account)) {
          this.validAccount = false
        } else this.validAccount = true
      } else this.validAccount = true
      if (!this.validAccount && this.newMethod.currency) this.createMethod()
    },
    createMethod() {
      createMethodApi(this.newMethod).then((resp) => {
        this.$emit('newMethod', resp.data)
      }, (errors) => {
        for (let error of errors.response.data.errors) {
          this.$toast.add({severity: 'error', detail: error, life: 3000});
        }
      })
    },
    getMethods() {
      getPaymentMethodsApi().then((resp) => {
        this.methods = resp.data.items
      }, (error) => {
        console.log(error)
      })
    },
  }
}
</script>
<style scoped lang="scss">
.label-checkbox {
  color: rgba(97, 105, 123, 1);
  font-size: 10px;
}
</style>
