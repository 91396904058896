import {api} from "./axios"
export function getUserPaymentMethodsApi() {
    return api.get(`/wallet/user-payment-methods`)
}

export function getPaymentMethodsApi() {
    return api.get(`/wallet/payment-methods`)
}
export function getCurrencyApi(id) {
    return api.get(`/wallet/payment-method/currency/${id}`)
}
export function createInvoiceApi(data, type) {
    return api.post(`/wallet/invoice/create/${type}`, data)
}

export function createMethodApi(data) {
    return api.post(`/wallet/user/create`, data)
}

export function putDefaultMethodApi(data) {
    return api.put(`/wallet/user/update/${data.id}/default`, data)
}
export function putStatusMethodApi(data) {
    return api.put(`/wallet/user/update/${data.id}/status`, data)
}

