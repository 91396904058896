import {api} from "./axios"
export function getStatsApi(params = {}, type) {
    return api.get(`/stats/${type}`, {params})
}
export function getFiltersStatsApi() {
    return api.get(`/stats/filter`)
}

export function getFilterStatsApi(params = {}, type) {
    return api.get(`/stats/${type}/filter`, {params})
}
export function getStatsPaidApi() {
    return api.get(`stats/paid/rs`)
}
export function getStatsDateApi(date, type, params = {}) {
    return api.get(`/stats/${type}/${date}/`, {params})
}
export function getStatsDateSaffApi(date, saff, type, params= {}) {
    return api.get(`/stats/${type}/${date}/${saff}`, {params})
}


export function getReportStats(type, params) {
    return api.get(`/stats/report/${type}`, {params})
}

export function getReportArchive() {
    return api.get(`/files/month-report`)
}