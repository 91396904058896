<template>
  <div>
    <DataTable :value="listData" scrollHeight="flex" class="expandTable" :loading="loading">
      <template #empty>
        <div class="text-center">{{ $t('t.no_data') }}</div>
      </template>
      <Column field="username" :header="$t('t.field.username')"></Column>
      <Column field="profit" :header="$t('t.field.profit')"></Column>
      <Column field="" :header="$t('t.field.status')">
        <template #body="">
          <my-chip v-if="status != 0"
                   :value="listStatus[status]? listStatus[status]: status"
                   :color="status == 3?'#2970C9': '#61697B'"/>
        </template>
      </Column>
      <Column field="fdCount" :header="$t('t.field.fd_count')"></Column>
      <Column field="dateRegistration" :header="$t('t.field.registration_date')"></Column>
    </DataTable>
  </div>
</template>

<script>


import {getRefDataApi} from "@/api/referral";
import MyChip from "@/components/myChip.vue";
import {mainStore} from "@/store/main";

export default {
  name: "expandData",
  components: {MyChip},
  props: ['date', 'type', 'filters', 'status'],
  data() {
    return {
      listData: [],
      loading: false
    }
  },
  mounted() {
    this.getData()
  },
  computed:{
    listStatus() {
      return mainStore().status
    },
  },
  methods: {
    getData() {
      this.loading = true
      getRefDataApi(this.date, this.type, this.filters).then((resp) => {
        this.listData = resp.data.items
        this.loading = false
      }, (error) => {
        this.loading = false
        console.log(error)
      })
    }
  }
}

</script>

<style scoped lang="scss">
::v-deep(.expandTable) {
  table {
    border: 1px solid rgba(238, 242, 252, 1);;
    border-collapse: separate;
    border-radius: 0 0 6px 6px;
    border-top: none;
  }
  .p-datatable-thead {
    z-index: 0!important;
  }
  .p-datatable-thead > tr > th {
    background: #ffffff;
    &:first-child {
      padding-left: 55px;
    }
  }

  .p-datatable-tbody > tr  {
    background: #ffffff;
  }
  .p-datatable-tbody > tr > td{
    border: 1px solid rgba(238, 242, 252, 1);
    border-width: 1px 0 0 0;
    border-radius: 0!important;
    &:first-child {
      padding-left: 55px;
    }
  }
}
</style>
