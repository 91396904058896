import {createI18n} from "vue-i18n";

const i18n = createI18n({
    locale: 'en',
    messages: {},
    silentTranslationWarn: true,
})

import {getTranslationsLang} from "@/api/lang";
import {mainStore} from "@/store/main";


export async function loadTranslations(locale) {
    if (mainStore().translations[locale]) {
        i18n.global.locale = locale
    } else {
        getTranslationsLang(locale).then((resp) => {
            i18n.global.setLocaleMessage(locale, resp.data)
            mainStore().setTranslation(locale, resp.data)
            mainStore().setGlobalLoading(false)
        }, (error) => {
            mainStore().setGlobalLoading(false)
            console.log(error)
        })
    }
    if (i18n.global.locale !== locale) {
        i18n.global.locale = locale
    }
}


export default i18n
