<template>
  <div class="auth">
    <a :href="linkLand"><img class="logo" src="/images/logo_small.svg" alt=""/></a>
    <router-view></router-view>
    <footer>Jimpartners.com - ©All rights reserved (2021)</footer>
  </div>
</template>

<script>
export default {
  name: "layoutLogin",
  computed: {
    linkLand() {
      return process.env.VUE_APP_URL_LANDING
    }
  }
}

</script>

<style scoped lang="scss">
.auth {
  position: relative;
  height: 100%;
  background-image: url("/public/images/bg_auth.jpg");
  background-position: center top;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo {
    margin: 20px 0 30px 0;
  }

  footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
    background: #04203C;
    color: #61697B;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 767px) {
  .auth {
    padding: 0 20px;
    background-image: url("/public/images/bg_auth_small.jpg");
  }
}
</style>
