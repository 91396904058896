import {api} from "./axios"
export function getProfileApi() {
    return api.get(`/profile`)
}
export function updateProfileApi(data) {
    return api.put(`/profile`, data)
}
export function updateAvatarApi(data) {
    return api.post(`/profile/avatar/update`, data)
}

export function deleteAvatarApi() {
    return api.delete(`/profile/avatar/remove`)
}

export function getCommissionApi() {
    return api.get(`/profile/comission`)
}
export function getCountriesApi() {
    return api.get(`/en/profile/location/country`)
}

export function changePasswordApi(data) {
    return api.put(`/user/change-password`, data)
}

export function getBalanceApi() {
    return api.get(`/user/balance`)
}
export function getUserManagerApi(locale) {
    return api.get(`${locale}/user/manager`)
}
export function getConditionsApi() {
    return api.get(`/user/conditions`)
}
