<template>
  <quick-stats/>
  <news/>
</template>

<script>
import QuickStats from "@/pages/Dashboard/components/quickStats";
import News from "@/pages/Dashboard/components/news";

export default {
  name: "index",
  components: {News, QuickStats}
}
</script>

<style scoped>
.link {
  color: var(--primary-color);
  font-size: 12px;
  text-decoration: underline;
}
</style>
