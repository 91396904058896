<template>
  <div>
    <div class="card-space payment-history" v-if="!versionMobile || currentBlock == 0"
         :class="mainStore().rsType? 'h-full':'adaptation'">
      <div class="header-card align-items-center mb-3">
        <div class="flex align-items-center">
          <div class="title-card mr-5">{{$t('t.payment_history')}}</div>
        </div>
        <div>
          <Button :label="$t('t.add_invoice')" size="small" @click="dialogInvoice = true"/>
        </div>
      </div>

      <div class="wrapper-table" :style="{height: heightTable}" @scroll="onScroll">
        <DataTable :value="listData"
                   class="main-table-custom">
          <template #empty>
            <div class="text-center">{{ $t('t.no_data') }}</div>
          </template>
          <Column field="date" :header="$t('t.field.date')"></Column>
          <Column field="amount" :header="$t('t.field.amount')">
            <template #body="slotProps">
              ${{ slotProps.data.amount }}
            </template>
          </Column>
          <Column v-if="type==='hybrid'" field="amountCpa" :header="$t('t.field.amount_cpa')">
            <template #body="slotProps">
              ${{ slotProps.data.amountCpa}}
            </template>
          </Column>
          <Column field="status" :header="$t('t.field.status')">
            <template #body="slotProps">
              <my-chip
                  :value="listStatus[slotProps.data.status]? listStatus[slotProps.data.status]: slotProps.data.status"
                  :color="slotProps.data.status == 3?'#2970C9': '#61697B'"/>
            </template>
          </Column>
          <Column field="paymentMethod" :header="$t('t.field.pay_method_id')" style="min-width: 150px"></Column>
          <Column field="payAccount" :header="$t('t.field.pay_account')">
            <template #body="slotProps">
              {{ slicePay(slotProps.data.payAccount) }}
            </template>
          </Column>
          <Column field="updatedAt" :header="$t('t.field.updated_at')"></Column>
          <Column field="crmPaymentFile" :header="$t('t.field.file')">
            <template #body="slotProps">
              <i class="pi pi-file color-grey"
                 v-if="(slotProps.data.paymentMethod == 'Bank Wire (only SEPA for EUR accounts)') && !slotProps.data.invoice"></i>
              <i class="pi pi-file color-primary"
                 v-else-if="(slotProps.data.paymentMethod == 'Bank Wire (only SEPA for EUR accounts)') && slotProps.data.invoice"
                 @click="downloadFile(slotProps.data.invoice)"></i>
              <span v-else> - </span>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>

    <Dialog v-model:visible="dialogInvoice" modal :header="$t('t.add_invoice')" :style="{ width: '50rem' }"
            :breakpoints="{ '575px': '90vw' }" class="hide-footer-dialog" :draggable="false">
      <modal-invoice @close="dialogInvoice = false" :type="type" :listOrders="listOrders" @initOrders="getOrders" :loadingOrders="loadingOrders"/>
      <template #footer></template>
    </Dialog>
  </div>
</template>

<script>

import {getFormOrderApi, getOrdersApi} from "@/api/order";
import MyChip from "@/components/myChip.vue";
import ModalInvoice from "@/pages/Finance/components/modalInvoice.vue";
import {mainStore} from "@/store/main";

export default {
  name: "paymentHistory",
  components: {MyChip, ModalInvoice},
  props: ["currentBlock", "versionMobile", "type"],
  data() {
    return {
      listData: [],
      page: 1,
      dialogInvoice: false,
      maxPage: 1,
      currentPage: 1,
      lazy: false,
      listOrders: [],
      loadingOrders: false,
    }
  },
  mounted() {
    if(mainStore().typeProfile) this.getData()
  },
  computed: {
    listStatus() {
      return mainStore().status
    },
    heightTable() {
      if (mainStore().rsType) {
        return this.versionMobile ? '69vh' : '77vh'
      } else {
        return this.versionMobile ? '64vh' : '73vh'
      }
    }
  },
  methods: {
    mainStore,
    getOrders() {
      this.loadingOrders = true
      getFormOrderApi(this.type).then((resp) => {
        this.listOrders = resp.data.orders
        this.loadingOrders = false
      }, (error) => {
        this.loadingOrders = false
        console.log(error)
      })
    },
    onScroll(e) {
      if (!this.lazy && this.currentPage < this.maxPage) {
        if (e.target.offsetHeight + e.target.scrollTop > e.target.scrollHeight - 150) {
          ++this.currentPage
          this.getData()
        }
      }
    },
    downloadFile(url) {
      let a = document.createElement("a");
      a.href = url;
      a.click();
    },
    nameStatus(id) {
      if (this.listStatus[id]) return this.listStatus[id]
      return id
    },
    slicePay(value) {
      if (value) return value.substr(1, 18) + '...'
      return ''
    },
    getData() {
      this.lazy = true
      getOrdersApi(this.currentPage, this.type).then((resp) => {
        this.listData.push(...resp.data.items)
        this.maxPage = resp.data.pagination.numPages
        this.lazy = false
      }, (error) => {
        console.log(error)
      })
    },

  }
}
</script>

<style scoped lang="scss">
.payment-history {
  margin-right: 24px;
}

.wrapper-table {
  overflow: auto;

  ::v-deep(.p-datatable) {
    height: 100%;

    .p-datatable-wrapper {
      height: 100%;
      position: relative;
      overflow: visible !important;
      .p-datatable-thead {
        top: -1px;
      }

    }
  }
}

@media screen and (max-width: 767px) {
  .payment-history {
    width: 100%;
  }
}
</style>
