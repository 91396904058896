<template>
  <div class="menu" :class="[version == 'mobile'? 'mob-version': '']">
    <ul v-if=" version != 'mobile'">
      <li v-for="item in menu1" :key="item.url">
        <router-link :to="{name: item.url}" class="item-menu">
          <img class="icon" alt="" :src="item.icon">
          <span class="name">{{ item.name }}</span>
        </router-link>
      </li>
    </ul>
    <div>
      <ul v-if=" version == 'mobile'">
        <li v-for="item in menu2" :key="item.url">
          <router-link :to="{name: item.url}" class="item-menu">
            <img class="icon" alt="" :src="item.icon">
            <span class="name">{{ item.name }}</span>
          </router-link>
        </li>
      </ul>
      <div class="user-widget">
        <div>{{ $t('t.menu.yourmanager') }}:</div>
        <div class="name-user splice">👋 {{ infoManager.name }}</div>
        <div class="divider">
          <div></div>
        </div>
        <div class="contact" v-if="infoManager.telegram">
          <img src="images/icons/telegram.svg" alt>
          <a class="splice" :href="'tg://resolve?domain=' + infoManager.telegram"
             target="_blank">{{ infoManager.telegram }}</a>
        </div>
        <div class="contact" v-if="infoManager.whatsapp">
          <i class="pi pi-whatsapp" style="font-size: 12px; color: #8E9BBB"/>
          <a class="splice" :href="'https://wa.me/' + infoManager.whatsapp" target="_blank">{{
              infoManager.whatsapp
            }}</a>
        </div>
        <div class="contact" v-if="infoManager.skype">
          <img src="images/icons/skype.svg" alt>
          <a class="splice" :href="'skype:' + infoManager.skype + '?chat'" target="_blank">{{ infoManager.skype }}</a>
        </div>
        <div class="contact" v-if="infoManager.email">
          <img src="images/icons/mail.svg" alt>
          <a class="splice" :href="'mailto:' + infoManager.email" target="_blank">{{ infoManager.email }}</a>
        </div>
      </div>
    </div>
    <div>
      <ul v-if=" version != 'mobile'">
        <li v-for="item in menu2" :key="item.url">
          <router-link :to="{name: item.url}" class="item-menu">
            <img class="icon" alt="" :src="item.icon">
            <span class="name">{{ item.name }}</span>
          </router-link>
        </li>
      </ul>
      <div class="item-menu btn-out" @click="logout">
        <img class="icon" alt="" src="images/icons/out.svg">
        <span class="name">{{ $t('t.menu.logout') }}</span>
      </div>
    </div>
  </div>
</template>

<script>

import {getUserManagerApi} from "@/api/profile";
import {mainStore} from "@/store/main";
import {clearLocalStorage} from "@/helpers";

export default {
  name: "menu",
  props: ["version"],
  data() {
    return {
      infoManager: {}
    }
  },
  mounted() {
    this.getManager(this.local)
  },
  computed: {
    menu1() {
      return [
        {name: this.$t('t.menu.dashboard'), url: "dashboard", icon: "images/icons/dashboard.svg"},
        {name: this.$t('t.menu.statistics'), url: "statistics", icon: "images/icons/statistics.svg"},
        {name: this.$t('t.menu.campaigns'), url: "campaigns", icon: "images/icons/campaigns.svg"},
        {name: this.$t('t.menu.finance'), url: "finance", icon: "images/icons/finance.svg"},
      ]
    },
    menu2() {
      return [
        {name: this.$t('t.menu.profile'), url: "profile", icon: "images/icons/profile.svg"},
        {name: this.$t('t.menu.myreferrals'), url: "referrals", icon: "images/icons/referrals.svg"},
        {name: this.$t('t.menu.news'), url: "news", icon: "images/icons/news.svg"},
        // {name: "Conferences", url: "conferences", icon: "images/icons/conferences.svg"},
        {name: this.$t('t.menu.faq'), url: "faq", icon: "images/icons/faq.svg"},
      ]
    },
    local() {
      return this.$i18n.locale
    }
  },
  watch:{
    local(){
      this.getManager(this.local)
    }
  },
  methods: {
    logout() {
      this.$router.push({name: 'auth'})
      clearLocalStorage()
      setTimeout(() => mainStore().clearType(), 0)
    },
    getManager(locale) {
      getUserManagerApi(locale).then((resp) => {
        this.infoManager = resp.data
      }, (error) => {
        console.log(error.message)
      })
    }
  }
}
</script>

<style scoped lang="scss">

a {
  text-decoration: none;
}

.menu {
  width: 220px;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 1px 4px 11px 1px #88A2FF1F;

  padding: 2px 20px;
  background-image: url("/public/images/JP.png");
  background-position: bottom;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.item-menu {
  display: flex;
  align-items: center;
  height: 44px;
  padding-left: 16px;
  border-radius: 5px;
  position: relative;
  margin-top: 3px;

  &:hover {
    background: linear-gradient(89.92deg, #E6F0FF 5.07%, rgba(230, 240, 255, 0) 99.93%);
    cursor: pointer;
  }
}

.router-link-active {
  background: linear-gradient(89.92deg, #E6F0FF 5.07%, rgba(230, 240, 255, 0) 99.93%);

  &::before {
    content: "";
    height: 32px;
    border-radius: 3px 0 0 3px;
    position: absolute;
    left: -2px;
    border-left: 2px solid var(--primary-color);
  }
}

.name {
  font-size: 14px;
  font-weight: 400;
  color: #03192F;
}

.icon {
  margin-right: 12px;
}

.user-widget {
  color: #61697B;
  font-size: 12px;
  padding: 16px;
  height: fit-content;
  width: 100%;
  border: 1px solid #DAE2F3;
  border-radius: 8px;
  background: rgba(218, 226, 243, 0.1);
  margin-top: 8px;

  .name-user {
    color: #212529;
    font-weight: 600;
    margin-top: 4px;
    line-height: normal;
  }

  .contact {
    margin-top: 8px;
    display: flex;
    align-items: center;
    font-size: 10px;

    img, i {
      margin-right: 4px;
    }

    a {
      color: #61697B;
    }
  }
}


.btn-out {
  background: rgba(218, 226, 243, 0.1);
  border: 1px solid #DAE2F3;
  border-radius: 8px;
  height: 44px;
  margin-top: 10px;
  margin-bottom: 24px;
}

.mob-version {
  width: 100%;
  background-color: #ffffff;
  box-shadow: none;
  padding: 2px 1rem;
  height: 89.9%;
  background-size: contain;
}
</style>
