<template>
  <ul class="menu flex md:hidden">
    <li v-for="item in menu" :key="item.url" style="width: 25%">
      <router-link :to="{name: item.url}" class="item-menu">
        <img class="icon" alt="" :src="item.icon">
        <span class="name">{{ item.name }}</span>
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: "menuBottomMob",
  data() {
    return {
    }
  },
  computed:{
    menu() {
      return [
        {name: this.$t('t.menu.dashboard'), url: "dashboard", icon: "images/icons/dashboard.svg"},
        {name: this.$t('t.menu.statistics'), url: "statistics", icon: "images/icons/statistics.svg"},
        {name: this.$t('t.menu.campaigns'), url: "campaigns", icon: "images/icons/campaigns.svg"},
        {name: this.$t('t.menu.finance'), url: "finance", icon: "images/icons/finance.svg"},
      ]
    }
  }
}
</script>

<style scoped lang="scss">
a {
  text-decoration: none;
}

.menu {
  z-index: 51;
  position: fixed;
  bottom: 0;
  display: flex;
  width: 100%;
  background-color: #F9FBFF;
  border-top: 1px solid #F2F4F8;
}

.item-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 54px;
  border-bottom: 3px solid #F9FBFF;
}

.name {
  font-size: 10px;
  font-weight: 400;
  color: #03192F;
}

.icon {
  margin-bottom: 5px;
}

.router-link-active {
  background: linear-gradient(0.95deg, #E6F0FF 6.51%, rgba(230, 240, 255, 0) 99.18%);
  border-radius: 0 0 3px 3px;
  border-bottom: 3px solid var(--primary-color);
}
</style>
