<template>
  <div class="hidden md:block">
    <div class="mb-3">{{$t('t.choose_filter')}}</div>
    <div class="flex" :class="checkFilters()? 'mb-3': ''">

      <div style="width: 180px" class="mr-3">
        <custom-dropdown ref="period" :options="periods" :name="$t('t.create_date')" v-model="periodFilter">
          <template v-slot="{props}">
            <div v-if="props.index === 0" @click.stop="$refs.panelCalendar.show($event)"
                 class="flex justify-content-between  item custom-period">
              <b>{{ props.option.name }}</b>
              <i class="pi pi-pencil"></i>
              <OverlayPanel ref="panelCalendar" class="overlay-panel-custom" @click.stop>
                <div class="flex justify-content-end"><i @click="$refs.panelCalendar.hide()"
                                                         class="pi pi-times cursor-pointer"></i></div>
                <Calendar @click.stop v-model="date" inline selectionMode="range" :numberOfMonths="2"
                          dateFormat="dd.mm.yy"
                          :hideOnRangeSelection="true">
                  <template #footer>
                    <div class="flex justify-content-end p-1">
                      <Button class="mr-3" outlined :label="$t('t.reset')" @click="deleteFilter('period')"/>
                      <Button :label="$t('t.done')" style="width: 140px"
                              @click="setDate"/>
                    </div>
                  </template>
                </Calendar>
              </OverlayPanel>
            </div>
            <div v-else class="item" v-html="props.option.name"></div>
          </template>
        </custom-dropdown>
      </div>

      <div style="width: 180px">
        <custom-dropdown name="Type" :options="listFilters.type" optionLabel="id" optionValue="id"
                         v-model="type">
        </custom-dropdown>
      </div>

    </div>
    <div class="flex justify-content-between">
      <div class="flex flex-wrap gap-1" ref="chips">
        <custom-chip v-if="periodFilter" :id="dateChip" :name="dateChip"
                     @deleteChip="deleteFilter('period')"/>
        <custom-chip v-if="type" :name="type"
                     :id="type" @deleteChip="deleteFilter('type')"/>
      </div>
      <div class="chip-reset" v-if="checkFilters()" @click="resetFilters">
        <span>{{$t('t.reset_filters')}}</span>
        <i class="pi pi-times close"></i>
      </div>
    </div>
  </div>

    <div class="block md:hidden" v-if="!show">
      <Button icon="pi pi-filter" style="width: 55px" @click="this.$emit('update:show', true)"/>
  </div>
  <div class="block md:hidden w-full" v-if="show">
      <div class="header-card">
        <div class="title-card" @click="this.$emit('update:show', false)"><i style="font-size: 12px"
                                                                             class="pi pi-chevron-left"></i> Filters
        </div>
      </div>

      <custom-dropdown ref="period" :options="periods" name="Create date" v-model="periodFilter">
        <template v-slot="{props}">
          <div v-if="props.index === 0" @click.stop="$refs.panelCalendar.show($event)"
               class="flex justify-content-between  item custom-period">
            <b>{{ props.option.name }}</b>
            <i class="pi pi-pencil"></i>
            <OverlayPanel ref="panelCalendar" class="overlay-panel-custom" @click.stop>
              <div class="flex justify-content-end"><i @click="$refs.panelCalendar.hide()"
                                                       class="pi pi-times cursor-pointer"></i></div>
              <Calendar @click.stop v-model="date" inline selectionMode="range" :numberOfMonths="2"
                        dateFormat="dd.mm.yy"
                        :hideOnRangeSelection="true">
                <template #footer>
                  <div class="flex justify-content-end p-1">
                    <Button class="mr-3" outlined :label="$t('t.reset')" @click="deleteFilter('period')"/>
                    <Button :label="$t('t.done')" style="width: 140px"
                            @click="setDate"/>
                  </div>
                </template>
              </Calendar>
            </OverlayPanel>
          </div>
          <div v-else class="item" v-html="props.option.name"></div>
        </template>
      </custom-dropdown>

      <custom-chip v-if="periodFilter" class="mr-2 mb-3" :id="dateChip" :name="dateChip"
                   @deleteChip="deleteFilter('period', $event)"/>

      <custom-dropdown name="Type" :options="listFilters.type" optionLabel="id" optionValue="id"
                       v-model="type">
      </custom-dropdown>
      <custom-chip v-if="type" :name="type"
                   :id="type" @deleteChip="deleteFilter('type')"/>
  </div>
</template>

<script>
import customChip from "@/components/customChip";
import customDropdown from "@/components/customDropdown";
import moment from "moment";
import {getFiltersPostbackApi} from "@/api/postback";
import {mainStore} from "@/store/main";

export default {
  name: "filterPostback",
  components: {customChip, customDropdown,},
  props: {
    modelValue: {},
    show: {},
  },
  data() {
    return {
      date: '',
      periods: [
        {name: 'Custom', id: 'custom'},
      ],
      listFilters: {},
      periodFilter: '',
      dateChip: null,
      type: null,
    }
  },
  mounted() {
    if(mainStore().typeProfile) {
      this.getFilters()
      this.$watch(() => [this.type, this.periodFilter], () => {
        this.search()
      })
    }
  },
  computed: {
  },
  watch: {
    periodFilter() {
      if (Object.keys(this.listFilters).length) {
        const date = this.listFilters.select_period[this.periodFilter]
        if (date) {
          this.dateChip = date
        }
      }
    },
    modelValue() {
      for (let filter in this.modelValue) {
        if (filter == 'select_period') {
          if (!this.periods.find(p => p.name == this.dateChip)) {
            this.periodFilter = this.modelValue[filter]
            const parseDate = this.periodFilter.split(' - ')
            const startDate = moment(parseDate[0], 'YYYY-MM-DD').format('DD.MM.YYYY')
            const endDate = moment(parseDate[1], 'YYYY-MM-DD').format('DD.MM.YYYY')
            this.dateChip = startDate + ' - ' + endDate
          }
        } else this[filter] = this.modelValue[filter]
      }
    }
  },
  methods: {
    checkFilters() {
      if (this.periodFilter || this.type) {
        return true
      }
      return false
    },
    resetFilters() {
      this.date = ''
      this.periodFilter = ''
      this.dateChip = null
      this.type = null
    },
    parseFilter(data, name) {
      if (Object.keys(this.listFilters).length) {
        return data.map(v => {
          return this.listFilters[name].find(f => f.id == v)
        })
      } else return []
    },
    search() {
      let filters = {
        'select_period': this.periodFilter,
        'type': this.type,
      }

      for (let key in filters) {
        if (!filters[key] || !filters[key].length) delete filters[key]
      }
      this.$emit('update:modelValue', filters)
    },
    getFilters() {
      getFiltersPostbackApi().then((resp) => {
        let filters = resp.data
        filters.type = filters.type.map(v => {return {id: v}})
        this.listFilters = filters
      })
    },

    setDate() {
      if (this.date && this.date[0] && this.date[1]) {
        const startDate = moment(this.date[0]).format('DD.MM.YYYY')
        const endDate = moment(this.date[1]).format('DD.MM.YYYY')
        this.periodFilter = moment(this.date[0]).format('YYYY-MM-DD') + ' - ' + moment(this.date[1]).format('YYYY-MM-DD')
        this.dateChip = startDate + ' - ' + endDate
        this.$refs.panelCalendar.hide()
        this.$refs.period.hide()
      }
    },
    deleteFilter(key) {
      if (key === 'period') {
        this.date = null
        this.periodFilter = null
        this.dateChip = null

      }
      if (key === 'type') {
        this.type = null
      }
    }
  }
}
</script>

<style scoped lang="scss">
.col {
  padding: 0 0.5rem !important;
}

@media screen and (max-width: 767px) {
  ::v-deep(.solo) {
    height: 46px !important;
    margin-bottom: 10px;
  }
  ::v-deep(.p-datepicker-group-container) {
    display: flex;
    flex-direction: column;
  }
  ::v-deep(.p-datepicker.p-datepicker-multiple-month .p-datepicker-group) {
    border-left: none;
  }
}
</style>
