<template>
  <InputText class="w-full mb-3" placeholder="Enter link name*" v-model="dataForm.name"
             :class="{ 'p-invalid': !dataForm.name.trim() && !valid }"/>
  <div class="name-parameter" :class="{ 'p-invalid': !dataForm.brand && !valid }">{{ $t('t.choose_brand') }}*</div>
  <div class="brand-wrapper">
    <div class="brand-block">
      <div class="brand" v-for="brand in form.brands" :title="brand.name" @click="dataForm.brand = brand.id"
           :class=" dataForm.brand == brand.id? 'active-brand' : ''">
        <img :src="brand.icon"/>
      </div>
    </div>
  </div>
  <template v-if="dataForm.brand">
    <div class="name-parameter" :class="{ 'p-invalid':!dataForm.landing && !valid }">{{ $t('t.choose_landing') }}*</div>
    <div class="grid mb-4">
      <div v-for="land in landings" :class="versionMobile?'col-6':'col-3'">
        <div class="flex align-items-center mb-1">
          <RadioButton v-model="dataForm.landing" :inputId="land.name" name="dynamic" :value="land.id"/>
          <label :for="land.name" class="radio-label">{{ land.name }}</label>
        </div>
        <Image :src="land.icon? land.icon: 'images/no-img.png'" class="img-landing" :alt="land.name"
               preview/>
      </div>
    </div>
    PAGINATION
    <div class="name-parameter">{{ $t('t.set_up_params') }}</div>

    <div class="flex align-items-center mb-3">
      <RadioButton v-model="paramView" inputId="parameters" :value="true"/>
      <label for="parameters" class="ml-2" style="color: #61697B;font-size: 14px;">{{ $t('t.add_parameters') }}</label>
    </div>
    <template v-if="paramView">
      <div class="flex align-items-center mb-2">
        <label class="parameter-label">utm_campaign</label>
        <InputText class="w-full" size="small" v-model="parameters.utmCampaign"/>
      </div>
      <div class="flex align-items-center mb-2">
        <label class="parameter-label">utm_medium</label>
        <InputText class="w-full" size="small" v-model="parameters.utmMedium"/>
      </div>
      <div class="flex align-items-center mb-2">
        <label class="parameter-label">utm_source</label>
        <InputText class="w-full" size="small" v-model="parameters.utmSource"/>
      </div>
      <div class="flex align-items-center mb-2">
        <label class="parameter-label">utm_term</label>
        <InputText class="w-full" size="small" v-model="parameters.utmTerm"/>
      </div>
      <div class="flex align-items-center mb-2">
        <label class="parameter-label">utm_content</label>
        <InputText class="w-full" size="small" v-model="parameters.utmContent"/>
      </div>
    </template>
  </template>
  <div class="text-center">
    <Button label="Create" @click="createCamp"/>
  </div>
</template>
<script>
import {createCampaignApi, getFormCampApi, getLandingsCampApi} from "@/api/campaign";
import {mainStore} from "@/store/main";

export default {
  name: "ModalCampaign",
  props: ['type'],
  data() {
    return {
      form: {},
      dataForm: {name: '', brand: '', landing: ''},
      landings: [],
      paramView: false,
      parameters: {
        "utmCampaign": "",
        "utmSource": "",
        "utmMedium": "",
        "utmTerm": "",
        "utmContent": ""
      },
      valid: true
    }
  },
  mounted() {
    this.getForm()
  },
  computed: {
    versionMobile() {
      return mainStore().versionDisplay
    },
  },
  watch: {
    'dataForm.brand'() {
      this.getLandings()
    }
  },
  methods: {
    createCamp() {
      if (this.dataForm.name.trim() && this.dataForm.brand && this.dataForm.landing) {
        let data = this.dataForm
        if (this.form.saff && this.form.saff.length) data.saff = this.form.saff[0].id
        if (this.paramView) data = {...data, ...this.parameters}
        createCampaignApi(data, this.type).then((resp) => {
          this.$emit('addCampaign', resp.data)
          this.$emit('close')
          this.$toast.add({severity: 'success', detail: this.$t('t.message.create_campaign'), life: 3000});
        }, (error) => {
          console.log(error)
        })
      } else this.valid = false
    },
    getForm() {
      getFormCampApi().then((resp) => {
        this.form = resp.data
      }, (error) => {
        console.log(error)
      })
    },
    getLandings() {
      getLandingsCampApi(this.dataForm.brand, {limit: 8}).then((resp) => {
        this.landings = resp.data.items
      }, (error) => {
        console.log(error)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.name-parameter {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 12px;
}

.name-parameter.p-invalid {
  color: #dc3545;
}

.brand-wrapper {
  margin-bottom: 20px;
  overflow-x: auto;
}

.brand-block {
  display: flex;
  border: 1px solid #DEE3EF;
  border-radius: 5px;
  width: fit-content;


  :last-child {
    border: none;
    border-radius: 0 5px 5px 0;
  }

  :first-child {
    border-radius: 5px 0 0 5px;
  }

  .brand {
    cursor: pointer;
    width: 70px;
    height: 40px;
    padding: 6px 8px;
    border-right: 1px solid #DEE3EF;
    display: flex;
    align-items: center;

    &:hover {
      background: #DDE5FB;
    }

    img {
      width: 100%
    }
  }
}


.active-brand {
  border: 1px solid rgba(23, 31, 235, 0.5) !important;
  background: #DDE5FB;
}


.radio-label {
  color: #61697B;
  margin-left: 3px;
  font-size: 10px;
}

::v-deep(.p-image) {
  width: 100%;

  img {
    width: 100%;
    border-radius: 5px;
    height: 89.75px;
  }

  .p-image-preview-indicator {
    border-radius: 5px;
  }
}

.parameter-label {
  color: #212529;
  font-size: 10px;
  width: 25%;
}

@media screen and (max-width: 767px) {
  .brand-wrapper {
    overflow-x: hidden;
  }
  .brand-block {
    display: flex;
    flex-wrap: wrap;
    border: none;
    width: fit-content;

    .brand:last-child {
      border: 1px solid #DEE3EF;
      border-radius: 5px;
    }

    .brand:first-child {
      border-radius: 5px;
    }
  }
  .brand {
    cursor: pointer;
    width: 64px;
    padding: 5px 8px;
    border: 1px solid #DEE3EF;
    border-radius: 5px;
    margin: 0 10px 10px 0;
  }
  .grid {
    margin: 0;
  }

  ::v-deep(.p-image) {
    img {
      height: 83.25px;
    }
  }
}

</style>
