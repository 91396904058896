<template>
  <div v-if="showValid" class="message">
    <div class="title">Link is not active</div>
    <div><span class="text">Back</span><router-link :to="{name: 'auth', hash: '#login'}" class="link">Log in</router-link></div>
  </div>
  <template v-else>
  <div class="card-block" v-if="showForm">
    <h1 class="title">New password</h1>
    <div class="field-valid">
      <div class="p-input-icon-left field">
        <i class="pi pi-lock"></i>
        <Password v-model="password" placeholder="Create a password" :feedback="false" toggleMask
                  :class="{ 'p-invalid': errors.password }"/>
      </div>
      <span class="p-error">{{ errors.password }}</span>
    </div>

    <div class="field-valid">
      <div class="p-input-icon-left field">
        <i class="pi pi-lock"></i>
        <Password v-model="passwordConfirm" placeholder="Confirm a password" :feedback="false" toggleMask
                  :class="{ 'p-invalid': errors.passwordConfirm }"/>
      </div>
      <span class="p-error">{{ errors.passwordConfirm }}</span>
    </div>

    <Button label="Submit" @click="reset" :outlined="!checkCredits" :disabled="!checkCredits" class="auth-btn mt-3"
            :class="{'active-btn': checkCredits}"/>
  </div>
    <div v-else class="message">
      <div class="title">Password updated</div>
      <div><span class="text">Back</span><router-link :to="{name: 'auth', hash: '#login'}" class="link">Log in</router-link></div>
    </div>
  </template>
</template>

<script setup>

import * as yup from 'yup';
import {useForm} from 'vee-validate';
import {computed, onMounted, ref} from "vue";
import {useToast} from "primevue/usetoast";
import {getNewPasswordApi, newPasswordApi,} from "@/api/auth";
import {useRoute, useRouter} from "vue-router";


const toast = useToast();
const route = useRoute();
const router = useRouter();

const passwordRegExp = /^[A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/

const schema = yup.object({
  password: yup.string().required('Required field').matches(passwordRegExp, 'Must be a valid').min(6, "Min 6").trim(),
  passwordConfirm: yup.string().required('Required field').oneOf([yup.ref('password')], 'Passwords must match').trim()
})

const {defineField, handleSubmit, errors} = useForm({
  validationSchema: schema,
});

const [password] = defineField('password');
const [passwordConfirm] = defineField('passwordConfirm');
const showForm = ref(true);
const showValid = ref(true);
const token = ref('');

const checkCredits = computed(() => {
  if (password.value && passwordConfirm.value) {
    return true
  } else return false
})


const validForm = handleSubmit(values => {
  return values
});

onMounted(() => {
  if (route.query.token){
    token.value = route.query.token
    router.replace({'query': null, hash: '#new-password'});
    getForm(token.value)
  }
})
function getForm(token){
  getNewPasswordApi(token).then(() => {
    showValid.value = false
    }, (error) => {
      toast.add({severity: 'error', detail: error.response.data.error, life: 3000});
    })
}
async function reset() {
  let form = await validForm()
  if (form) {
    newPasswordApi(token.value,form).then(() => {
      showForm.value = false
    }, (error) => {
      toast.add({severity: 'error', detail: error.response.data.error, life: 3000});
    })
  }
}


</script>

<style scoped lang="scss">
@import url('/src/assets/styles/auth.scss');
.card-block{
  min-height: 350px;
}
.message{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20%;
}
.title {

  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 16px;
}
.text{
  color: #0B2D4E;
  font-weight: 500;
  margin-right: 6px;
  font-size: 16px;
}
.link{
  font-weight: 500;
  font-size: 16px;
}
</style>
