<template>
  <div>
    <div class="card-space adaptation">
      <div class="header-card align-items-center mb-3">
        <div class="flex align-items-center" :class="versionMobile?'mb-3':''">
          <div class="title-card mr-5">{{ $t('t.menu.my_campaigns') }}</div>
          <slot v-if="versionMobile"></slot>
        </div>
        <div class="flex">
          <slot v-if="!versionMobile"></slot>
          <Button v-if="type === 'rs'" :class="versionMobile?'':'ml-3'" :label="$t('t.add_campaign')" size="small"
                  @click="dialogCampaign = true"/>
          <Button v-else :class="versionMobile?'':'ml-3'" :label="$t('t.request_link')" size="small" @click="showLink"/>
        </div>
      </div>
      <div class="wrapper-table" :style="{height: heightTable}" @scroll="onScroll">
        <DataTable :value="listData" class="main-table-custom" @sort="sort" removableSort lazy>
          <template #empty>
            <div class="text-center">{{ $t('t.no_data') }}</div>
          </template>
          <Column v-for="column in columnsType" :field="column.key" :header="column.name">
            <template #body="slotProps">
              <div v-if="column.key === 'geoLocationParams'" class="local-params"><span v-for="param in slotProps.data.geoLocationParams">{{param.name}}-{{param.value}}</span>
              </div>
              <template v-else>{{ slotProps.data[column.key] }}</template>
            </template>
          </Column>

          <Column header="" style="min-width:120px">
            <template #body="slotProps">
              <i class="pi pi-chart-bar color-grey mr-3" @click="$router.push({name:'statistics', query: { promos:[slotProps.data.id], type:type }})"></i>
              <i class="pi pi-clone color-grey mr-3" @click="copyLink(slotProps.data.id)"></i>
              <i class="pi pi-download color-grey" @click="routerStats(slotProps.data.id)"></i>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <Dialog v-model:visible="dialogCampaign" modal :header="$t('t.create_campaign')" :style="{ width: '50rem' }"
            :breakpoints="{ '575px': '90vw', '767px': '90vw'  }" class="hide-footer-dialog" :draggable="false">
      <modal-campaign @close="dialogCampaign=false" :type="type" @addCampaign="listData.unshift($event)"/>
      <template #footer></template>
    </Dialog>
    <Dialog v-model:visible="dialogLink" modal header=" " :style="{ width: '30rem' }"
            :breakpoints="{ '575px': '35vw', '767px': '90vw'  }" class="hide-footer-dialog" :draggable="false">
      <div class="">
        <div class="link-text">{{ $t('t.text.campaign_links') }}</div>
        <div class="flex justify-content-center" v-if="Object.keys(infoManager).length">
          <div class="img-link mr-4"><a :href="'tg://resolve?domain=' + infoManager.telegram" target="_blank"><img
              src="/images/link_tg.png"/></a>
          </div>
          <div class="img-link mr-4"><a :href="'skype:' + infoManager.skype + '?chat'" target="_blank"><img
              src="/images/link_skype.png"/></a>
          </div>
          <div class="img-link"><a :href="'mailto:' + infoManager.email" target="_blank"><img src="/images/link_mail.png"/></a>
          </div>
        </div>
      </div>
      <template #footer></template>
    </Dialog>
  </div>
</template>

<script>
import {getCampaignsApi, getLinkCampApi} from "@/api/campaign";
import {mainStore} from "@/store/main";
import ModalCampaign from "@/pages/Campaigns/components/modalCampaign.vue";
import moment from "moment";
import {getUserManagerApi} from "@/api/profile";
import copy from 'copy-to-clipboard'

export default {
  name: "CampaignBlock",
  components: {ModalCampaign},
  props: ['type'],
  data() {
    return {
      listData: [],
      maxPage: 1,
      currentPage: 1,
      lazy: false,
      dialogCampaign: false,
      dialogLink: false,
      infoManager: {}
    }
  },
  mounted() {
    if(mainStore().typeProfile) this.getData()
  },
  computed: {
    columnsRS() {
      return [
        {name: this.$t('t.field.created_at'), key: 'createdAt'},
        {name: this.$t('t.field.link_name'), key: 'name'},
        {name: this.$t('t.field.brand'), key: 'brand'},
        {name: this.$t('t.field.landing'), key: 'landing'},
      ]
    },
    columnsHybrid() {
      return [
        {name: this.$t('t.field.code'), key: 'code'},
        {name: this.$t('t.field.plan'), key: 'plan'},
        {name: this.$t('t.field.name'), key: 'name'},
        {name: this.$t('t.field.brand'), key: 'brand'},
        {name: this.$t('t.field.landing'), key: 'landing'},
        {name: this.$t('t.field.pay_sum'), key: 'paySum'},
        {name: this.$t('t.field.progress_type'), key: 'progressType'},
        {name: this.$t('t.field.progress'), key: 'progress'},
        {name: this.$t('t.field.geo_locations'), key: 'geoLocations'},
        {name: this.$t('t.field.geo_locations_params'), key: 'geoLocationParams'},
        {name: this.$t('t.field.base_line_type'), key: 'baseLineType'},
        {name: this.$t('t.field.base_line'), key: 'baseLine'},
      ]
    },
    columnsCPA() {
      return [
        {name: this.$t('t.field.code'), key: 'code'},
        {name: this.$t('t.field.plan'), key: 'plan'},
        {name: this.$t('t.field.name'), key: 'name'},
        {name: this.$t('t.field.brand'), key: 'brand'},
        {name: this.$t('t.field.landing'), key: 'landing'},
        {name: this.$t('t.field.fdt_count'), key: 'countFtdTest'},
        {name: this.$t('t.field.progress_type'), key: 'progressType'},
        {name: this.$t('t.field.progress'), key: 'progress'},
        {name: this.$t('t.field.geo_locations'), key: 'geoLocations'},
        {name: this.$t('t.field.geo_locations_params'), key: 'geoLocationParams'},
        {name: this.$t('t.field.base_line_type'), key: 'baseLineType'},
        {name: this.$t('t.field.base_line'), key: 'baseLine'},
      ]
    },
    versionMobile() {
      return mainStore().versionDisplay
    },
    heightTable() {
      return this.versionMobile ? '64vh' : '73vh'
    },
    columnsType() {
      return this.type === 'rs' ? this.columnsRS : this.type === 'cpa' ? this.columnsCPA : this.columnsHybrid
    },
    local() {
      return this.$i18n.locale
    }
  },
  watch:{
    local(){
      this.getManager(this.local)
    }
  },
  methods: {
    mainStore,
    showLink() {
      if (!Object.keys(this.infoManager).length) this.getManager(this.local)
      this.dialogLink = true
    },
    getManager(locale) {
      getUserManagerApi(locale).then((resp) => {
        this.infoManager = resp.data
      }, (error) => {
        console.log(error.message)
      })
    },
    routerStats(promo) {
      const start_data = moment().subtract(30, 'days').format('YYYY-MM-DD');
      const end_data = moment().format('YYYY-MM-DD');
      this.$router.push({name: 'statistics', query: {promos: promo, select_period: start_data + ' - ' + end_data, type: this.type}})
    },
    copyLink(id) {
      getLinkCampApi(id).then((resp) => {
        // navigator.clipboard.writeText(resp.data.content)
        copy(resp.data.content, {
          message: this.$t('t.copy_clipboard'),
        })
        this.$toast.add({severity: 'success', detail: 'Copied!', life: 500});
      }, (error) => {
        console.log(error)
      })
    },
    onScroll(e) {
      if (!this.lazy && this.currentPage < this.maxPage) {
        if (e.target.offsetHeight + e.target.scrollTop > e.target.scrollHeight - 150) {
          ++this.currentPage
          this.getData()
        }
      }
    },
    sort(e) {
      console.log(e)
    },
    getData() {
      this.lazy = true
      getCampaignsApi(this.currentPage, this.type).then((resp) => {
        this.listData.push(...resp.data.items)
        this.maxPage = resp.data.pagination.numPages
        this.lazy = false
      }, (error) => {
        console.log(error)
      })
    },
  }
}
</script>

<style scoped lang="scss">
.local-params span:not(:last-child):after {
  content: ', ';
}
.link-text {
  color: #03192F;
  text-align: center;
  margin-bottom: 20px;
}

.img-link {
  height: 60px;
  width: 60px;

  img {
    width: 100%
  }
}

.wrapper-table {
  overflow: auto;

  ::v-deep(.p-datatable) {
    height: 100%;

    .p-datatable-wrapper {
      height: 100%;
      position: relative;
      overflow: visible !important;

      .p-datatable-thead {
        top: -1px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .header-card {
    display: block !important;
    margin-bottom: 14px !important;
  }
}
</style>
