<template>
  <div class="wrapper">
    <div class="title">thank you!</div>
    <div class="text">Expect a letter from us with suggestions!</div>
  </div>
</template>

<script>
export default {
  name: 'resetMessage'
}
</script>

<style scoped lang="scss">
.wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20%;
}
.title {
  background: radial-gradient(39.06% 143.88% at 76.04% 4.17%, #3B95FF 0%, #171FEB 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 16px;
}
.text{
  color: #0B2D4E;
  font-weight: 500;
}
</style>
