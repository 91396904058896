import {api} from "@/api/axios";

export function getPostbackApi(params, type) {
    return api.get(`/postback/${type}/log`, {params})
}

export function getFormPostbackApi(type) {
    return api.get(`/postback/${type}/form/`)
}

export function getFiltersPostbackApi() {
    return api.get(`/postback/filter`)
}
