import {api} from "@/api/axios";

export function getReferralsApi(params, type) {
    return api.get(`/stats/${type}/referral/`, {params})
}

export function getRefDataApi(date,type, params) {
    return api.get(`/stats/${type}/referral/${date}/`, {params})
}
export function getFiltersRefApi() {
    return api.get(`/stats/filter/referral`)
}
