<template>
  <div class="card-space mb-4">
    <div class="header-card align-items-center">
      <div class="block-left">
        <div class="flex justify-content-between w-full">
          <div class="title-card">{{$t('t.quick_stats')}}</div>
          <router-link tag="a" class="link" v-if="versionMobile" :to="{name: 'statistics'}">{{$t('t.full_statistics')}}
          </router-link>
        </div>
        <div class="flex">
          <div class="select-filter mr-2 ">
            <custom-dropdown :viewValue="true" :name="$t('t.all_brands')" :multi="true" :options="listFilters.brands"
                             optionLabel="name" optionValue="id"
                             v-model="brands" @change="filterStats">
            </custom-dropdown>
          </div>
          <div class="select-filter">
            <custom-dropdown :viewValue="true" ref="period" :options="periods" :name="$t('t.period')" v-model="periodFilter"
                             @change="filterStats">
              <template v-slot="{props}">
                <div v-if="props.index === 0" @click.stop="showCalendar($event)"
                     class="flex justify-content-between item custom-period">
                  <b>{{ props.option.name }}</b>
                  <i class="pi pi-pencil"></i>
                  <OverlayPanel ref="panelCalendar" class="overlay-panel-custom" @click.stop>
                    <div class="flex justify-content-end"><i @click="$refs.panelCalendar.hide()"
                                                             class="pi pi-times cursor-pointer"></i></div>
                    <Calendar @click.stop v-model="date" inline selectionMode="range" :numberOfMonths="2"
                              dateFormat="dd.mm.yy"
                              :hideOnRangeSelection="true">
                      <template #footer>
                        <div class="flex justify-content-end p-1">
                          <Button class="mr-3" outlined :label="$t('t.reset')" @click="resetCustomPeriod"/>
                          <Button :label="$t('t.done')" style="width: 140px"
                                  :disabled="(date && date[0] && date[1])? false : true"
                                  @click="setDate"/>
                        </div>
                      </template>
                    </Calendar>
                  </OverlayPanel>
                </div>
                <div v-else class="item" v-html="props.option.name"></div>
              </template>
            </custom-dropdown>
          </div>
        </div>
      </div>
      <router-link tag="a" class="link" v-if="!versionMobile" :to="{name: 'statistics'}">{{$t('t.full_statistics')}}</router-link>
    </div>
    <div class="stats grid">
      <div class="col item" v-for="stat in currentStat">
        <span class="name">{{ stat.name }}</span>
        <i v-if="loading" class="pi pi-spin pi-spinner value"></i>
        <span class="value" v-else>{{ stat.pre }}{{ stat.value }}</span>
      </div>
    </div>
  </div>

</template>

<script>
import customDropdown from "@/components/customDropdown";
import {getFiltersStatsApi, getFilterStatsApi} from "@/api/statistics";
import moment from "moment/moment";
import debounce from "debounce";
import {mainStore} from "@/store/main";
import cloneDeep from "clone-deep";

export default {
  name: "quickStats",
  components: {customDropdown},
  data() {
    return {
      listFilters: {},
      brands: [],
      date: '',
      cloneDate: '',
      periods: [
        {name: 'Custom', id: 'custom'},
      ],
      periodFilter: null,
      period: "",
      debouncedFilter: () => {
      },
      loading: false,
      statsValueRS: {
        hosts: '',
        registration: '',
        fdCount: '',
        fdSum: '',
        depositSum: '',
        rsProfit: '',
      },
      statsValueHybrid: {
        hosts: '',
        registration: '',
        fdCount:'',
        fdSum: '',
        depositSum: '',
        rsProfit: '',
        cpaProfit: '',
      },
      statsValueCPA: {
        hosts: '',
        registration: '',
        fdCount: '',
        ftdApproved: '',
        cpaProfit: '',
      }
    }
  },
  mounted() {
    if(mainStore().typeProfile){
      this.loading = true
      this.debouncedFilter = debounce(this.getData, 1500);
      this.getFilters()
    }
  },
  computed: {
    statsRS(){ return {
      hosts: {name: this.$t('t.field.visits'), value: this.statsValueRS.hosts},
      registration: {name: this.$t('t.field.registrations'), value: this.statsValueRS.registration},
      fdCount: {name: this.$t('t.field.ftd'), value: this.statsValueRS.fdCount},
      fdSum: {name: this.$t('t.field.fdt_sum'), value: this.statsValueRS.fdSum, pre: '$'},
      depositSum: {name: this.$t('t.field.deposit_sum'), value: this.statsValueRS.depositSum, pre: '$'},
      rsProfit: {name: this.$t('t.field.rs_profit'), value: this.statsValueRS.rsProfit, pre: '$'},
    }},
    statsHybrid(){ return {
      hosts: {name: this.$t('t.field.visits'), value: this.statsValueHybrid.hosts},
      registration: {name: this.$t('t.field.registrations'), value: this.statsValueHybrid.registration},
      fdCount: {name: this.$t('t.field.ftd'), value: this.statsValueHybrid.fdCount},
      fdSum: {name: this.$t('t.field.fdt_sum'), value: this.statsValueHybrid.fdSum, pre: '$'},
      depositSum: {name: this.$t('t.field.deposit_sum'), value: this.statsValueHybrid.depositSum, pre: '$'},
      rsProfit: {name: this.$t('t.field.rs_profit'), value: this.statsValueHybrid.rsProfit, pre: '$'},
      cpaProfit: {name: this.$t('t.field.cpa_profit'), value: this.statsValueHybrid.cpaProfit, pre: '$'},
    }},
    statsCPA(){ return {
      hosts: {name: this.$t('t.field.visits'), value: this.statsValueCPA.cpaProfit},
      registration: {name: this.$t('t.field.registrations'), value: this.statsValueCPA.registration},
      fdCount: {name: this.$t('t.field.ftd'), value: this.statsValueCPA.fdCount},
      ftdApproved: {name: this.$t('t.field.approwed_ftd'), value: this.statsValueCPA.ftdApproved, pre: '$'},
      cpaProfit: {name: this.$t('t.field.profit'), value: this.statsValueCPA.cpaProfit, pre: '$'},
    }},
    versionMobile() {
      return mainStore().versionDisplay
    },
    currentStat(){
      if(mainStore().typeProfile){
        let type
        if (mainStore().typeProfile.length > 1) {
          type = 'hybrid'
        } else type = mainStore().typeProfile[0].id
        return  type === 'rs'? this.statsRS: type === 'hybrid'? this.statsHybrid : this.statsCPA
      } return {}

    }
  },
  methods: {
    showCalendar(e) {
      if (this.cloneDate) this.date = this.cloneDate
      this.$refs.panelCalendar.show(e)
    },
    resetCustomPeriod() {
      this.cloneDate = cloneDeep(this.date)
      this.date = ''
    },
    filterStats() {
      this.loading = true
      this.debouncedFilter()
    },
    getFilters() {
      getFiltersStatsApi().then((resp) => {
        let filters = resp.data.items
        const select_period = []
        Object.entries(filters.selectPeriod).forEach(([key, value]) => {
          if (value.includes('From the 16th')) {
            select_period.push({name: 'From the 16th - to the  end of the current month', id: key})
          } else select_period.push({name: value, id: key})
        })
        this.periods.push(...select_period)
        this.listFilters = filters
        this.periodFilter = this.periods.find(v => v.name === "This month").id
        this.getData(this.periodFilter)
      }, (error) => {
        console.log(error.message)
      })
    },
    setDate() {
      if (this.date && this.date[0] && this.date[1]) {
        this.period = moment(this.date[0]).format('YYYY-MM-DD') + ' - ' + moment(this.date[1]).format('YYYY-MM-DD')
        this.periodFilter = 'custom'
        this.$refs.panelCalendar.hide()
        this.filterStats()
        this.cloneDate = ''
        this.$refs.period.hide()
      }
    },
    getData() {
      if (this.periodFilter !== "custom") {
        this.date = ''
        this.period = ''
        this.cloneDate = ''
      }
      let type = ''
      if (mainStore().typeProfile.length > 1) {
        type = 'hybrid'
      } else type = mainStore().typeProfile[0].id
      getFilterStatsApi({
        brands: this.brands,
        select_period: this.periodFilter === "custom" ? this.period : this.periodFilter,
        limit: 40
      }, type).then((resp) => {
        const stats = type === 'rs'? this.statsValueRS: type === 'hybrid'? this.statsValueHybrid : this.statsValueCPA
        for (let stat in stats) {
          let sum = 0
          for (let item of resp.data.items) {
            sum += item[stat]
          }
          stats[stat] = Number(sum.toFixed(2))
          this.loading = false
        }
      }, (error) => {
        console.log(error.message)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.header-card {
  margin-bottom: 1.2rem !important;
}

.title-card {
  margin-right: 2rem;
}

.block-left {
  display: flex;
  align-items: center;

  .select-filter {
    width: 230px;
  }
}

.stats {
  width: 100%;
  height: 82px;
  background-color: #F9FBFF;
  border-radius: 5px;
  margin: 0;
  margin-bottom: 6px;

  .col {
    padding: 0.85rem;
  }

  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    .name {
      color: #61697B;
      font-weight: 600;
      font-size: 12px;
      margin-bottom: 12px;
    }

    .value {
      color: var(--primary-color);
      font-size: 20px;
      font-weight: 700;
    }
  }

  .item + .item:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 1px;
    height: 60%;
    background: #BFC7DA;;
    transform: translateY(-50%);
  }
}

@media screen and (max-width: 767px) {
  .header-card {
    margin-bottom: 1rem;
  }
  .title-card {
    margin-bottom: 0;
  }
  .block-left {
    width: 100%;
    flex-direction: column;
    align-items: start;

    .title-card {
      margin-bottom: 20px;
    }

    .select-filter {
      width: 150px;
    }
  }
  .stats {
    height: 150px;
    padding: 0 5px;

    .item {

      .name {
        font-size: 10px;
        width: max-content;
      }

      .value {
        font-size: 14px;
      }
    }

    .item + .item:before {
      display: none;
    }
  }
}

</style>
