<template>
  <Login v-if="activePage ==='login'"/>
  <ResetPassword v-if="activePage ==='reset'"/>
  <Registration v-if="activePage ==='sign-up'"/>
  <NewPassword v-if="activePage ==='new-password'"/>
</template>
<script>
import Login from "./components/login.vue";
import ResetPassword from "./components/resetPassword.vue";
import Registration from "@/pages/Auth/components/registration.vue";
import NewPassword from "@/pages/Auth/components/newPassword.vue";

export default {
  name: "index",
  components: {Login, ResetPassword, Registration, NewPassword},
  computed:{
    activePage(){
      if(this.$route.hash){
        return this.$route.hash.slice(1)
      } return 'login'
    }
  },
}

</script>
<style scoped lang="scss">

</style>
