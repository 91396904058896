//10 мин - 600000
export function helperToken(data){
    localStorage.setItem('crm_token', data.token)
    // localStorage.setItem('refresh_token', data.refresh_token)
    const crmExpire = (new Date).getTime() + 28800000
    localStorage.setItem('crm_expire', crmExpire.toString())
}

export function helperTokenManager(token){
    localStorage.setItem('crm_token', token)
    const crmExpire = (new Date).getTime() + 900000
    localStorage.setItem('crm_expire', crmExpire.toString())
}

export function clearLocalStorage(){
    localStorage.removeItem('crm_token')
    localStorage.removeItem('crm_expire')
}
