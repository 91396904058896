<template>
  <div class="card-space adaptation h-full">
    <div class="header-card align-items-center mb-3" >
      <div class="flex align-items-center" :class="versionMobile?'mb-3':''" v-if="!filtersMob">
        <div class="title-card mr-5">Postback list</div>
        <slot v-if="versionMobile"></slot>
      </div>
      <div class="flex" v-if="!versionMobile">
        <slot></slot>
        <Button class="ml-3" label="Export" size="small" icon="pi pi-file-export"
                @click="downloadCsv"/>
      </div>
    </div>
    <filter-postback v-if="!versionMobile" v-model="filters"/>
    <div v-if="versionMobile" class="flex justify-content-between mb-3">
      <Button :label="$t('t.export')" size="small" icon="pi pi-file-export"
              @click="downloadCsv" v-show="!filtersMob"/>
      <filter-postback v-model="filters" v-model:show="filtersMob"/>
    </div>
    <div class="divider" v-if="!versionMobile">
      <div></div>
    </div>

    <div class="wrapper-table" ref="table" :style="{height: heightTable}" @scroll="onScroll" v-show="!filtersMob">
      <DataTable :value="listData" class="main-table-custom" removableSort lazy>
        <template #empty>
          <div class="text-center">{{ $t('t.no_data') }}</div>
        </template>
        <Column field="dateCreate" :header="$t('t.field.created_at')"></Column>
        <Column field="id" header="ID"></Column>
        <Column field="type" :header="$t('t.field.type')"></Column>
        <Column field="referrerUserId" :header="$t('t.field.refferrer_user_id')"></Column>
        <Column field="status" :header="$t('t.field.status')"></Column>
        <Column field="clickId" :header="$t('t.field.click_id')"></Column>
        <Column field="link" :header="$t('t.field.links')" style="max-width:160px">
          <template #body="slotProps">
            <div class="flex align-items-center">
              <div class="splice mr-1">{{ slotProps.data.link }}</div>
              <i v-if="slotProps.data.link" class="pi pi-copy color-grey" @click="copyBuffer(slotProps.data.link)"></i>
            </div>
          </template>
        </Column>
        <Column field="responseContent" :header="$t('t.field.response_content')">
          <template #body="slotProps">
            <div :style="slotProps.data.responseContent == null? '':'#dc3545'">{{slotProps.data.responseContent == null? 'Success': slotProps.data.responseContent}}</div>
          </template>
        </Column>
        <Column field="statusCode" :header="$t('t.field.status_code')"></Column>
      </DataTable>
    </div>
  </div>
</template>

<script>

import {mainStore} from "@/store/main";
import {getPostbackApi} from "@/api/postback";
import cloneDeep from "clone-deep";
import filterPostback from "@/pages/Campaigns/components/filterPostback.vue";


export default {
  name: "PostbackBlock",
  components: {filterPostback},
  props: ['type'],
  data() {
    return {
      listData: [],
      maxPage: 1,
      currentPage: 1,
      lazy: false,
      dialogCampaign: false,
      filters: {},
      filtersMob: false
    }
  },
  mounted() {
    if(mainStore().typeProfile) this.getData()
  },
  watch: {
    filters: {
      handler() {
        this.currentPage = 1
        this.getData()
      },
      deep: true
    },
  },
  computed: {
    versionMobile() {
      return mainStore().versionDisplay
    },
    heightTable() {
      return this.versionMobile ? '64vh' : '65vh'
    }
  },
  methods: {
    mainStore,
    copyBuffer(data) {
      this.$toast.add({severity: 'success', detail: this.$t('t.message.copied'), life: 1000});
      navigator.clipboard.writeText(data)
    },
    onScroll(e) {
      if (!this.lazy && this.currentPage < this.maxPage) {
        if (e.target.offsetHeight + e.target.scrollTop > e.target.scrollHeight - 150) {
          ++this.currentPage
          this.getData()
        }
      }
    },
    getData() {
      this.lazy = true
      getPostbackApi({page: this.currentPage, ...this.filters}, this.type).then((resp) => {
        if (this.currentPage == 1) {
          if(this.$refs.table)this.$refs.table.scrollTop = 0
          this.listData = resp.data.items
        } else this.listData.push(...resp.data.items)
        this.maxPage = resp.data.pagination.numPages
        this.lazy = false
      }, (error) => {
        console.log(error)
      })
    },
    downloadCsv() {
      let cloneData = cloneDeep(this.listData)
      let headers = [this.$t('t.field.created_at'), "ID", this.$t('t.field.type'), this.$t('t.field.refferrer_user_id'), this.$t('t.field.status'), this.$t('t.field.click_id'), this.$t('t.field.links'), this.$t('t.field.response_content'), this.$t('t.field.status_code')]
      const refinedData = []
      refinedData.push(headers)
      for (let item of cloneData) {
        const sortData = {
          dateCreate: item.dateCreate,
          id: item.id,
          type: item.type,
          referrerUserId: item.referrerUserId,
          status: item.status,
          clickId: item.clickId,
          link: item.link,
          responseContent: item.responseContent,
          statusCode: item.statusCode,
        }

        refinedData.push(Object.values(sortData))
      }
      let csvContent = ''
      refinedData.forEach(row => {
        csvContent += row.join(',') + '\n'
      })
      let a = document.createElement("a");
      const file = new Blob([csvContent], {type: 'text/csv;charset=utf-8'})
      a.href = URL.createObjectURL(file);
      a.download = "data.csv";
      a.click();
    },
  }
}
</script>

<style scoped lang="scss">
.wrapper-table {
  overflow: auto;

  ::v-deep(.p-datatable) {
    height: 100%;

    .p-datatable-wrapper {
      height: 100%;
      position: relative;
      overflow: visible !important;
      .p-datatable-thead {
        top: -1px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .header-card {
    display: block !important;
    margin-bottom: 14px !important;
  }

}
</style>
