<template>
  <div class="my-chip" :style="style">{{ value }}</div>
</template>

<script>

export default {
  name: "index",
  props: {
    color: {
      type: String,
      default: '#61697B'
    },
    value: {
      default: 'paid'
    },
  },
  data() {
    return {
      style: {
        color: this.color,
        border: '0.5px solid ' + this.color,
        background: this.color.slice(0, 7) + 19
      }
    }
  },
}

</script>

<style scoped lang="scss">
.my-chip {
  padding: 4px;
  border-radius: 3px;
  width: fit-content;
  text-transform: uppercase;
  font-size: 8px;
  font-weight: 700;
}
</style>
