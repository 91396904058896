<template>
  <div class="loading" v-if="mainStore().globalLoading"><i class="pi pi-spin pi-spinner spinner"></i></div>
  <div class="main" v-show="!mainStore().globalLoading">
    <Header/>
    <div class="desk">
      <div class="hidden md:block"><Menu/></div>
      <div class="work-space">
      <router-view></router-view>
      </div>
    </div>
    <menu-bottom-mob/>
  </div>
</template>

<script>

import Header from "@/components/header";
import Menu from "@/components/menu";
import MenuBottomMob from "@/components/menuBottomMob";
import {mainStore} from "@/store/main";

export default {
  name: "layoutMain",
  methods: {mainStore},
  components: {MenuBottomMob, Menu, Header},
}
</script>

<style scoped>
.loading{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .spinner{
    font-size: 80px;
    color: rgba(41, 112, 201, 0.32);
  }
}
.main {
  height: 100%;
  background-color: #F9FBFF;
  position: relative;
}
.desk{
  display: flex;
  height: calc(100% - 81px);
}
.work-space{
  padding: 24px 20px 24px 40px;
  width: calc(100% - 220px);
  height: 100%;
}
@media screen and (max-width: 767px){
  .work-space {
    width: 100%;
    padding: 14px 20px 50px 20px;
    margin-top: 64px;
    margin-bottom: 20px;
    height: fit-content;
  }
  .desk{
    height: 100%;
    overflow: auto;
  }
}
</style>
