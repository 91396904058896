<template>
  <div style="height: calc(100% - 30px)">
    <template v-for="type in activeTypes">
      <PostbackBlock v-if="mainStore().typeProfile" :type="type" v-show="typeActive == type"><TabsMenu v-model="typeActive" :small="true"/></PostbackBlock>
      <PostbackBlock v-else :type="type"><TabsMenu v-model="typeActive" :small="true"/></PostbackBlock>
    </template>
  </div>
</template>

<script>
import PostbackBlock from "@/pages/Campaigns/components/postbackBlock.vue";
import TabsMenu from "@/components/tabsMenu.vue";
import {mainStore} from "@/store/main";

export default {
  name: "Postback",
  components: {PostbackBlock, TabsMenu},
  data() {
    return {
      activeTypes: [],
      typeActive: null
    }
  },
  mounted() {
    if(mainStore().typeProfile){
      this.activeTypes.push(mainStore().typeProfile[0].id)
    } else this.activeTypes = ['']
  },
  watch: {
    typeActive() {
      if (!this.activeTypes.includes(this.typeActive)) {
        this.activeTypes.push(this.typeActive)
      }
    }
  },
  computed: {},
  methods: {mainStore},
}
</script>

<style scoped lang="scss">

</style>
