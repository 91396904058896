<template>
  <div class="chip">
    <span>{{ name }}</span>
    <i class="pi pi-times close" @click="$emit('deleteChip', id)"></i>
  </div>
</template>

<script>
export default {
  name: "custom-chip",
  props: ['name', 'id']
}
</script>

<style scoped lang="scss">
.chip {
  width: fit-content;
  padding: 8px;
  border-radius: 24px;
  border: 1px solid #DAE2F3;
  display: flex;
  align-items: center;

  span{
    font-size: 12px;
    color: #03192F;
  }

  .close{
    margin-left: 8px;
    color: #8E9BBB;

    &:hover{
      cursor: pointer;
    }
  }
}
</style>
