<template>
  <div class="header-card align-items-center mb-2">
    <div class="flex align-items-center">
      <div class="title-card mr-5">{{$t('t.my_referral_statistics')}}</div>
      <div style="width: 180px" class="mr-2 hidden md:block">
        <custom-dropdown :name="$t('t.period')" :options="periods" v-model="periodFilter">
          <template v-slot="{props}">
            <div class="item" v-html="props.option.name"></div>
          </template>
        </custom-dropdown>
      </div>
      <div style="width: 180px" class="hidden md:block">
        <custom-dropdown :name="$t('t.sub_referral')" :options="listFilters.referrals" v-model="referralsFilter"
                         optionLabel="username" :option-value="null"></custom-dropdown>
      </div>
    </div>
    <Button class="hidden md:inline-flex" :label="$t('t.search')" size="small" icon="pi pi-search" @click="search"/>
  </div>
  <div class="hidden md:flex justify-content-between">
    <div class="flex flex-wrap gap-1">
      <custom-chip v-if="periodFilter" class="mr-2" :id="dateChip" :name="dateChip"
                   @deleteChip="deleteFilter('period', $event)"/>
      <custom-chip v-if="referralsFilter" class="mr-2" :name="referralsFilter.username"
                   :id="referralsFilter.id" @deleteChip="deleteFilter('referral', $event)"/>
    </div>
    <div class="chip-reset" v-if="checkFilters" @click="resetFilters">
      <span>{{$t('t.reset_filters')}}</span>
      <i class="pi pi-times close"></i>
    </div>
  </div>
  <div class="block md:hidden mt-3 mb-3">
    <div class="flex">
      <div style="width: 110px" class="mr-2">
        <custom-dropdown ref="period" :name="$t('t.period')" :options="periods" v-model="periodFilter">
          <template v-slot="{props}">
            <div v-if="props.index === 0" @click.stop="$refs.panelCalendar.show($event)"
                 class="flex justify-content-between  item custom-period">
              <b>{{ props.option.name }}</b>
              <i class="pi pi-pencil"></i>
              <OverlayPanel ref="panelCalendar" class="overlay-panel-custom" @click.stop>
                <div class="flex justify-content-end"><i @click="$refs.panelCalendar.hide()"
                                                         class="pi pi-times cursor-pointer"></i></div>
                <Calendar @click.stop v-model="date" inline selectionMode="range" :numberOfMonths="2"
                          dateFormat="dd.mm.yy"
                          :hideOnRangeSelection="true">
                  <template #footer>
                    <div class="flex justify-content-end p-1">
                      <Button class="mr-3" outlined :label="$t('t.reset')" @click="deleteFilter('period')"/>
                      <Button :label="$t('t.done')" style="width: 140px"
                              @click="setDate"/>
                    </div>
                  </template>
                </Calendar>
              </OverlayPanel>
            </div>
            <div v-else class="item" v-html="props.option.name"></div>
          </template>
        </custom-dropdown>
      </div>
      <div style="width: 130px" class="mr-2">
        <custom-dropdown :name="$t('t.sub_referral')" :options="listFilters.referrals" v-model="referralsFilter"
                         optionLabel="username" :option-value="null"></custom-dropdown>
      </div>
      <Button size="small" icon="pi pi-search" style="height: 28px" @click="search"/>
    </div>
    <div class="flex flex-wrap gap-1">
      <custom-chip v-if="periodFilter"  :id="dateChip" :name="dateChip"
                   @deleteChip="deleteFilter('period', $event)"/>
      <custom-chip v-if="referralsFilter" :name="referralsFilter.username"
                   :id="referralsFilter.id" @deleteChip="deleteFilter('referral', $event)"/>
      <div class="chip-reset" v-if="checkFilters" @click="resetFilters">
        <span>{{$t('t.reset_filters')}}</span>
        <i class="pi pi-times close"></i>
      </div>
    </div>

  </div>
</template>

<script>
import customDropdown from "@/components/customDropdown.vue";
import {getFiltersRefApi} from "@/api/referral";
import moment from "moment/moment";
import customChip from "@/components/customChip.vue";
import {mainStore} from "@/store/main";

export default {
  name: "filters",
  components: {customChip, customDropdown},
  data() {
    return {
      listFilters: {},
      date: '',
      periods: [
        // {name: 'Custom', id: 'custom'},
      ],
      periodFilter: null,
      referralsFilter: null,
      dateChip: null,
    }
  },
  mounted() {
    if (mainStore().typeProfile) this.getFilters()
  },
  computed: {
    checkFilters() {
      if (this.periodFilter || this.referralsFilter) {
        return true
      }
      return false
    },
  },
  watch: {
    periodFilter() {
      const date = this.listFilters.selectPeriod[this.periodFilter]
      if (date) {
        this.dateChip = date
      }
    }
  },
  methods: {
    resetFilters() {
      this.date = ''
      this.periodFilter = null
      this.referralsFilter = null
      this.dateChip = null
      this.$emit('filter', {})
    },
    search() {
      const filters = {
        'select_period': this.periodFilter,
        'subId': this.referralsFilter ? this.referralsFilter.id : '',
      }
      for (let key in filters) {
        if (!filters[key]) delete filters[key]
      }
      if(Object.keys(filters).length) this.$emit('filter', filters)
    },
    getFilters() {
      getFiltersRefApi().then((resp) => {
        let filters = resp.data.items
        const select_period = []
        Object.entries(filters.selectPeriod).forEach(([key, value]) => {
          if (value.includes('From the 16th')) {
            select_period.push({name: 'From the 16th - to the  <br> end of the current month', id: key})
          } else select_period.push({name: value, id: key})
        })
        this.periods.push(...select_period)
        this.listFilters = filters
      })
    },
    setDate() {
      if (this.date && this.date[0] && this.date[1]) {
        this.periodFilter = 'custom'
        const startDate = moment(this.date[0]).format('DD.MM.YYYY')
        const endDate = moment(this.date[1]).format('DD.MM.YYYY')
        this.dateChip = startDate + ' - ' + endDate
        this.$refs.panelCalendar.hide()
        this.$refs.period.hide()
      }
    },
    deleteFilter(key, value = null) {
      if (key === 'period') {
        this.date = null
        this.periodFilter = null
        this.dateChip = null
        return
      }
      if (key === 'referral') {
        this.referralsFilter = null
        return
      }
    }
  }
}
</script>

<style scoped lang="scss">

@media screen and (max-width: 767px) {
  ::v-deep(.solo) {
    margin-bottom: 10px;
  }
  ::v-deep(.p-datepicker-group-container) {
    display: flex;
    flex-direction: column;
  }
  ::v-deep(.p-datepicker.p-datepicker-multiple-month .p-datepicker-group) {
    border-left: none;
  }
}
</style>
