<template>
  <!--  <div class="block md:flex h-full">-->
  <!--    <div class="card-space topics">-->
  <!--      <div class="topic" :class="currentTopic == topic.id? 'active': ''" v-for="topic in topics"-->
  <!--           @click="currentTopic = topic.id">{{ topic.name }}-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    <TabMenu v-model:activeIndex="currentTopic" :model="topics" class="topics-mob">-->
  <!--      <template #item="{ item}">-->
  <!--        <div class="topic-mob" :class="currentTopic == item.id? 'active': ''">{{ item.name }}-->
  <!--        </div>-->
  <!--      </template>-->
  <!--    </TabMenu>-->
  <!--    <div class="card-space faq">-->
  <!--      <Accordion :multiple="true">-->
  <!--        <AccordionTab header="How much it is possible to earn with gambling affiliate program JimPartners.com?"-->
  <!--                      v-for="i in 5">-->
  <!--          <p>-->
  <!--            There is no limitation at all! Every participant of the program puts the selling himself. Your personal-->
  <!--            income on JimPartners.com lays fully on you and depends on your will, experience, ability to promote and of-->
  <!--            course time you spend on marketing.Job of webmaster as any other profession, requires attention, time and-->
  <!--            efforts. Nevertheless, even if you are still new in such issues, don`t be afraid of difficulties.-->
  <!--            Specialized AP office is always here to help you. Qualified support stuff helps webmasters to learn new-->
  <!--            marketing tools, optimize and improve advertising and increase your personal income.-->
  <!--          </p>-->
  <!--        </AccordionTab>-->
  <!--      </Accordion>-->
  <!--    </div>-->
  <!--  </div>-->

  <div class="block md:flex h-full">
    <div class="card-space topics">
      <template v-for="(topic, index) in listData">
        <div class="topic" v-if="topic.translations[local]" :class="currentTopic == index? 'active': ''"
             @click="currentTopic = index">{{ topic.translations[local].title }}
        </div>
        <div class="topic" v-else :class="currentTopic == index? 'active': ''"
             @click="currentTopic = index">{{ topic.title }}
        </div>
      </template>
    </div>
    <!--    && item.translations[localPrimeVue]-->
    <TabMenu v-if="listData.length" v-model:activeIndex="currentTopic" :model="listData" class="topics-mob">
      <template #item="{item, index}">
        <div class="topic-mob" v-if="item?.translations[local]" :class="currentTopic == index? 'active': ''">
          {{ item.translations[local].title }}
        </div>
      </template>
    </TabMenu>
    <div class="card-space faq">
      <!--      <div v-if="listData.length && listData[currentTopic].translations[localPrimeVue]"-->
      <!--           v-html="listData[currentTopic].translations[localPrimeVue]?.text"></div>-->
      <Accordion :multiple="true" :activeIndex="activeIndex">-->
        <AccordionTab :header="item.question"
                      v-for="item in faqAccordion">
          <div v-html="item.answer"></div>
        </AccordionTab>
      </Accordion>
    </div>
  </div>

</template>

<script>
import moment from "moment";
import {getArticlesApi} from "@/api/article";

export default {
  name: "index",
  data() {
    return {
      topics: [
        {id: 0, name: "General Questions"},
        {id: 1, name: "Financial questions"},
        {id: 2, name: "Technical Issues"},
      ],
      currentTopic: 0,
      activeIndex: [],
      listData: [],
    }
  },
  mounted() {
    this.getFaq()
  },
  watch: {
    currentTopic(){
this.activeIndex = []
    }
  },
  computed: {
    local() {
      return this.$i18n.locale
    },
    faqAccordion() {
      if (this.listData.length && this.listData[this.currentTopic].translations[this.local]) {
        const questions = this.listData[this.currentTopic].translations[this.local]?.text.match(/<strong>(.*?)<\/strong>/g).map(function (val) {
          return val.replace(/<\/?strong>/g, '');
        });

        let answers = this.listData[this.currentTopic].translations[this.local]?.text.match(/<\/strong><\/p>(.*?)<p><strong>/gs).map(function (val) {
          return val.replace(/<\/strong><\/p>/g, '');
        });

        const answerLast = this.listData[this.currentTopic].translations[this.local]?.text.match(/<\/strong><\/p>(?!.*<\/strong><\/p>).*/gs).map(function (val) {
          return val.replace(/<\/strong><\/p>/g, '');
        });
        answers = [...answers, ...answerLast]

        let faq = [];
        for (let i = 0; i < questions.length; i++) {
          faq.push({question: questions[i], answer: answers[i]})
        }
        return faq
      }
      return []
    }
  },
  methods: {
    formatDate(date) {
      if (date) {
        return moment(date).format("DD.MM.YYYY")
      } else return ''
    },
    getFaq() {
      getArticlesApi('faq').then((resp) => {
        this.listData = resp.data.items
      }, (error) => {
        console.log(error.message)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.topics-mob {
  display: none;
}

.topics {
  width: 20%;
  margin-right: 24px
}

.topic {
  background-color: #EEF2FC;
  color: #212529;
  font-weight: 600;
  font-size: 14px;
  padding: 16px 20px;
  border-radius: 5px;
  margin-bottom: 12px;
  cursor: pointer;
}

.faq {
  width: 80%;
  overflow: auto;
}

::v-deep(.p-accordion-tab) {
  margin-bottom: 12px !important;
}

::v-deep(.p-accordion-header-link) {
  background: #EEF2FC !important;
  border: none !important;
  color: #61697B !important;
  font-size: 14px;
  border-radius: 5px !important;
  text-decoration: none !important;

  &:focus {
    box-shadow: none !important;
  }
}

::v-deep(.p-accordion-header-text) {
  line-height: 16px;
}

::v-deep(.p-highlight .p-accordion-header-link) {
  border: 1px solid var(--primary-color) !important;
  color: var(--primary-color) !important;
  border-radius: 5px !important;
}

::v-deep(.p-accordion-content) {
  border: none !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 28px !important;
  color: #61697B !important;
}

::v-deep(.p-accordion-toggle-icon) {
  position: absolute;
  right: 12px;
}

@media screen and (max-width: 767px) {
  ::v-deep(.p-accordion-header-text) {
    width: 95% !important;
  }
  .topics {
    display: none;
  }
  .faq {
    width: 100%;
  }
  .topics-mob {
    margin: 0 -20px 20px -20px;
    display: block;
    padding-left: 20px;

    .topic-mob {
      padding: 8px 16px;
      border-radius: 8px;
      border: 1px solid var(--primary-color);
      margin-right: 10px;
      color: var(--primary-color);
      font-size: 12px;
      font-weight: 600;
      width: max-content;
    }
  }
}

.active {
  background-color: var(--primary-color);
  color: #ffffff !important;
}

::v-deep(.p-tabmenu .p-tabmenu-nav) {
  border: none !important;
}
</style>
